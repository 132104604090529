// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Button,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from '@material-ui/core';
import MUIDatatable from 'mui-datatables';
import PropTypes from 'prop-types';
import DashboardModal from '../dashboard/DashboardModal';
import WidgetTitle from '../help-button/widget-title';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import IDService from '../../services/IdService';
import { getSettings } from '../../helpers/data-helper';
import DataTableDetails from './DataTableDetails';
import TimeServiceProvider from '../../services/TimeService';
import CircularProgressCentral from '../common/CircularProgressCentral';
import ConfirmDialog from '../dialog/ConfirmDialog';
import { filters } from '../../signals/filterService';
import moment from 'moment';

const styles = {
  cardContent: {
    minHeight: 300,
    width: '100%',
    height: '100%',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    height: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 12,
  },
};

const timeService = TimeServiceProvider();

function DataTableWidget(props) {
  const [detalised, setDetalised] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [index, setIndex] = useState();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [showAIModal, setShowAIModal] = useState(false);
  const [aiOutputData, setAiOutputData] = useState([]);

  const {
    columns,
    columnsExpanded,
    settings,
    defaultSettings,
    url,
    dataFetchFunc,
    canDeleteRecord,
    deleteFunc,
    getAiOutPutFunc,
    entityName,
    classes,
  } = props;

  const fetchData = async (page, rowsPerPage, sortOrder = {}) => {
    const response = await dataFetchFunc(
      page,
      rowsPerPage,
      sortOrder.name,
      sortOrder.direction === 'desc'
    );
    const { data, total } = response;
    setData(data);
    setCount(total);
    setPage(page);
    setRowsPerPage(rowsPerPage);
    setSortOrder(sortOrder);
  };

  const resetTable = async () => {
    setIsLoading(true);
    await fetchData(0, 10, {});
    setIsLoading(false);
  };

  useEffect(() => {
    timeService.subscribe(resetTable);
    resetTable();

    return () => {
      timeService.unsubscribe(resetTable);
    };
  }, []);

  useEffect(
    function resetTableWhenFiltersChange() {
      resetTable();
    },
    [filters.value]
  );

  const { title, info, noDataLabel } = getSettings(settings, defaultSettings);

  const expandedColumns =
    columnsExpanded && columnsExpanded.length > 0 ? columnsExpanded : columns;
  const recordId = index >= 0 && data[index] ? data[index].id : '';

  const rowClickHandler = async (values, index) => {
    setIndex(index.dataIndex);
    setDetalised(true);
    const recordId =
      index.dataIndex >= 0 && data[index.dataIndex]
        ? data[index.dataIndex].id
        : '';
    try {
      const aiOutPuts = await getAiOutPutFunc(recordId);
      if (aiOutPuts) {
        setAiOutputData(aiOutPuts);
      }
    } catch (error) {
      console.error('Error fetching AI Output:', error);
    }
  };

  const tableChangeHandler = (action, tableState) => {
    if (['changePage', 'changeRowsPerPage', 'sort'].includes(action)) {
      fetchData(tableState.page, tableState.rowsPerPage, tableState.sortOrder);
    }
  };

  const onConfirmDeletion = async () => {
    const record = index >= 0 ? data[index] : null;
    if (record && deleteFunc) {
      await deleteFunc(record);
      setShowDeleteConfirmDialog(false);
      setDetalised(false);
      resetTable();
    }
  };

  function formatDateColumn(value) {
    return moment(value).format('DD/MMM/YY');
  }

  const options = {
    selectableRows: 'none',
    print: false,
    search: false,
    filter: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    onRowClick: rowClickHandler,
    onTableChange: tableChangeHandler,
    count,
    page,
    rowsPerPage,
    sortOrder,
  };

  const closeAIModal = () => {
    setShowAIModal(false);
    setAiOutputData('');
  };

  const onAIOutputClick = () => {
    try {
      if (aiOutputData) {
        setShowAIModal(true);
      } else {
        setShowAIModal(false);
      }
    } catch (error) {
      console.error('Error fetching AI Output:', error);
    }
  };

  const renderAIModalContent = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Hazards</TableCell>
            <TableCell>Root Causes</TableCell>
            <TableCell>Field Verification</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              {(aiOutputData.hazardTrends || []).length > 0
                ? aiOutputData.hazardTrends.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))
                : 'N/A'}
            </TableCell>
            <TableCell>
              {(aiOutputData.rootCauseTopics || []).length > 0
                ? aiOutputData.rootCauseTopics.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))
                : 'N/A'}
            </TableCell>
            <TableCell>
              {(aiOutputData.fieldVerificationTopics || []).length > 0
                ? aiOutputData.fieldVerificationTopics.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))
                : 'N/A'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return <CircularProgressCentral />;
    }
    if (columns?.length && data) {
      var dateColumns = columns.filter((c) =>
        c.name.toLowerCase().includes('date')
      );

      if (dateColumns?.length) {
        dateColumns.forEach((c) => {
          c.options = {
            ...c.options,
            customBodyRender: function (data) {
              if (data == null) {
                return '';
              } else {
                return formatDateColumn(data);
              }
            },
          };
        });
      }

      return (
        <>
          <MUIDatatable
            className={classes.table}
            data={data}
            columns={columns}
            options={options}
          />

          <DashboardModal
            handleClose={() => setExpanded(false)}
            title={title}
            open={expanded}
          >
            <MUIDatatable
              className={classes.table}
              data={data}
              columns={expandedColumns}
              options={options}
            />
          </DashboardModal>

          {recordId && (
            <DashboardModal
              handleClose={() => setDetalised(false)}
              title="Report Details:"
              open={detalised}
            >
              <DataTableDetails
                record={data[index]}
                columns={expandedColumns}
                url={url}
                id={recordId}
              />
              {aiOutputData.isSuperUser == true && (
                <div className={classes.actions}>
                  <Button variant="contained" onClick={() => onAIOutputClick()}>
                    AI Output
                  </Button>
                </div>
              )}
              <div className={classes.actions}>
                {canDeleteRecord && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setShowDeleteConfirmDialog(true)}
                  >
                    Delete
                  </Button>
                )}
              </div>
              {showAIModal && (
                <DashboardModal
                  handleClose={closeAIModal}
                  title={'AI Output for Report'}
                  open={showAIModal}
                  width="md"
                >
                  {renderAIModalContent()}
                </DashboardModal>
              )}
            </DashboardModal>
          )}
        </>
      );
    }
    return <span>{noDataLabel}</span>;
  };

  const elementId = IDService.Generate();
  return (
    <Card variant="outlined" className="flex-card">
      <CardHeader
        title={<WidgetTitle title={title} info={info} />}
        titleTypographyProps={{ variant: 'h6' }}
        action={
          <IconButton onClick={() => setExpanded(true)}>
            <ZoomOutMapIcon fontSize="small" />
          </IconButton>
        }
      />
      <CardContent id={elementId} className={classes.cardContent}>
        {renderContent()}
      </CardContent>
      <ConfirmDialog
        open={showDeleteConfirmDialog}
        onCancel={() => setShowDeleteConfirmDialog(false)}
        onConfirm={onConfirmDeletion}
        title={`Delete ${entityName}`}
        confirm="Delete"
      >
        <span>{`Are you sure you wish to delete this ${entityName}?`}</span>
      </ConfirmDialog>
    </Card>
  );
}

DataTableWidget.propTypes = {
  defaultSettings: PropTypes.object.isRequired,
  settings: PropTypes.object,
  columns: PropTypes.array,
  columnsExpanded: PropTypes.array,
  dataFetchFunc: PropTypes.func.isRequired,
  canDeleteRecord: PropTypes.bool,
  deleteFunc: PropTypes.func,
  getAiOutPutFunc: PropTypes.func,
  entityName: PropTypes.string,
};

DataTableWidget.defaultProps = {
  entityName: 'record',
};

export default withStyles(styles)(DataTableWidget);
