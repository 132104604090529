import React, { FC } from 'react';
import { Typography, withStyles, WithStyles } from '@material-ui/core';
import ClassificationRunDialog from './ClassificationRunDialog';

const styles = {
  container: {
    padding: '1em',
  },
  header: {
    marginBottom: '1em',
  },
};

interface SuperUserPanelProps extends WithStyles<typeof styles> {}

const SuperUserPanel: FC<SuperUserPanelProps> = ({ classes }) => (
  <div className={classes.container}>
    <Typography variant="h4" align="center" className={classes.header}>
      Super User Panel
    </Typography>
    <div>
      <ClassificationRunDialog />
    </div>
  </div>
);

export default withStyles(styles)(SuperUserPanel);
