import React, { useEffect, useState } from 'react';
import { AZURE_OPENAI_REPORTING_FUNCTION_URL, ROUTES } from '../../constants';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ClearFilters,
  filters,
  GenerateFilterParams,
  GetParams,
} from '../../signals/filterService';
import { makeStyles, Tooltip } from '@material-ui/core';
import useNarrativeReport, {
  NarrativeReportReq,
} from '../../hooks/useNarrativeReport';
import { toast } from 'react-toastify';
import AssociatedReportsDateCostChartData from '../../services/chart-data-shapes/AssociatedReportsDateValueChartData';
import TimeServiceProvider from '../../services/TimeService';
import useLoggedInUser from '../../useLoggedInUser';
import moment from 'moment';

const useStyles = makeStyles({
  dualActionChip: {
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'center',
    backgroundColor: 'transparent',
    padding: '10px 20px 5px 5px',
    borderRadius: '8px',
    width: '25%',
  },
  rightSide: {
    flex: 1,
    padding: '8px',
    fontSize: '14px',
    color: '#848B90',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    borderRadius: '0 8px 8px 0',
    border: '1px solid #848B90',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#848B90',
      color: 'black',
    },
    '&.active': {
      backgroundColor: '#F7DBD7',
      color: '#d94936',
      border: '1px solid #D94936',
      fontWeight: 'bold',
    },
  },
  leftSide: {
    border: '1px solid #848B90',
    flex: 1,
    padding: '8px',
    fontSize: '14px',
    color: '#848B90',
    fontWeight: 'normal',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    borderRadius: '8px 0 0 8px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#848B90',
      color: 'black',
    },
    '&.active': {
      backgroundColor: '#F7DBD7',
      color: '#d94936',
      border: '1px solid #D94936',
      fontWeight: 'bold',
    },
  },
  centerSide: {
    border: '1px solid #848B90',
    flex: 1,
    padding: '8px',
    fontSize: '14px',
    color: '#848B90',
    fontWeight: 'normal',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#848B90',
      color: 'black',
    },
    '&.active': {
      backgroundColor: '#F7DBD7',
      color: '#d94936',
      border: '1px solid #D94936',
      fontWeight: 'bold',
    },
  },
});

const timeService = TimeServiceProvider();

const DualActionChip: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const [shouldFetchReport, setShouldFetchReport] = useState(false);
  const { currentUser } = useLoggedInUser();

  const reqBody = (): NarrativeReportReq => {
    const rootCauses =
      GetParams()
        ['ReportRootCauseId']?.names?.split('|')
        .map((x: string) => ({
          type: 'ROOT_CAUSE',
          name: x,
        })) ?? [];

    const hazards =
      GetParams()
        ['ReportHazardId']?.names?.split('|')
        .map((x: string) => ({
          type: 'HAZARD',
          name: x,
        })) ?? [];

    const topics =
      GetParams()
        ['ReportTopicId']?.names?.split('|')
        .map((x: string) => ({
          type: 'TOPIC',
          name: x,
        })) ?? [];

    const [dateFrom, dateTo] = timeService.domain;

    const organisation =
      GetParams()['ReportOrganisationId']?.names?.split('|').at(0) ?? null;

    return {
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(dateTo).format('YYYY-MM-DD'),
      orgName: organisation ?? currentUser.primaryOrganisation.name,
      classifications: [...rootCauses, ...hazards, ...topics],
    };
  };

  const { triggerReportNarrative, error } = useNarrativeReport(
    AZURE_OPENAI_REPORTING_FUNCTION_URL,
    reqBody()
  );

  if (shouldFetchReport && error) {
    toast.error(error.message);
  }

  const [selectedSide, setSelectedSide] = useState<
    'left' | 'right' | 'center' | ''
  >('left');
  const location = useLocation();

  useEffect(() => {
    location.pathname.includes('/trackers')
      ? setSelectedSide('right')
      : location.pathname === '/'
      ? setSelectedSide('left')
      : setSelectedSide('');
  }, [location]);

  const handleReportClick = () => {
    setSelectedSide('center');
    triggerReportNarrative().then(({ ok }) =>
      ok
        ? toast.success('Report succes')
        : toast.error('Something went wrong when generating a report')
    );
  };

  const handleDiscoverClick = () => {
    setSelectedSide('left');
    ClearFilters();
    history.push(ROUTES.Dashboard);
  };

  const handleTrackerClick = () => {
    setSelectedSide('right');
    ClearFilters();
    history.push(ROUTES.Trackers);
  };

  return (
    <div className={classes.dualActionChip}>
      <div
        className={`${classes.leftSide} ${
          selectedSide === 'left' ? 'active' : ''
        }`}
        onClick={handleDiscoverClick}
      >
        Discover
      </div>
      {process.env.REACT_APP_REPORTING_FEATURE === '1' && (
        <Tooltip
          placement="top"
          title={
            'Disclaimer: This report will be created with Generative AI models. Advice and suggestions are NOT to be taken wholeheartedly'
          }
        >
          <div
            className={`${classes.centerSide} ${
              selectedSide === 'center' ? 'active' : ''
            }`}
            onClick={() => {
              if (reqBody().classifications.length > 0) {
                handleReportClick();
              } else {
                toast.error(
                  'Need to explicitly specify a Root Cause, Hazard or Topic'
                );
              }
            }}
          >
            <span>Report</span>
          </div>
        </Tooltip>
      )}
      <div
        className={`${classes.rightSide} ${
          selectedSide === 'right' ? 'active' : ''
        }`}
        onClick={handleTrackerClick}
      >
        Tracker / Alerts
      </div>
    </div>
  );
};

export default DualActionChip;
