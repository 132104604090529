import {
  Box,
  Divider,
  List,
  ListItem,
  Popover,
  Typography,
} from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { signal } from '@preact/signals-react';
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { AlertNotificationDto } from '../../api/apiDtos';
import API from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import CircularProgressCentral from '../common/CircularProgressCentral';
import { NotificationBell } from './NotificationBell';
import { NotificationItem } from './NotificationItem';

export type Guid = string;

const styles: CSSProperties = {
  maxHeight: 444, // Adjust based on the height of 6 items
  minHeight: 444, // Adjust based on the height of 6 items
  overflowY: 'auto', // Enables vertical scrolling
  overflowX: 'hidden',
};

const tabStyle: CSSProperties = {
  cursor: 'pointer',
};

const selectedTabStyle: CSSProperties = {
  color: 'red',
  cursor: 'pointer',
};

interface Props {}

//we use a signal so that we can set alertNotifications from outside the notification panel
//for example: when a user clicks on the link from an email and is sent to tracker page
export const alertNotifications = signal<AlertNotificationDto[]>([]);

export const NotificationPanel: FC<Props> = ({}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [loadingData, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'all' | 'unread'>('all');

  useEffect(function getAllAlertNotificationsOnLoad() {
    setLoading(true);

    API.get<unknown, AlertNotificationDto[]>(ApiRoutes.getAlertNotifications)
      .then((_alertNotifications) => {
        alertNotifications.value = _alertNotifications;
      })
      .finally(() => setLoading(false));
    //just safety: in case API route gets interrupted
    return () => {
      setLoading(false);
    };
  }, []);

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const markAllRead = () => {
    if (
      alertNotifications.value.length === 0 ||
      alertNotifications.value.every((x) => x.isRead)
    )
      return;

    API.post<Guid[], AlertNotificationDto[]>(
      ApiRoutes.markAllAlertNotificationRead,
      alertNotifications.value.map((x) => x.id)
    ).then((res) => {
      alertNotifications.value = res;
    });
  };

  const open = Boolean(anchorEl);

  const AllCaughtUp: FC<{}> = ({}) => {
    return (
      <ListItem
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '82px',
        }}
      >
        <Typography
          color="textSecondary"
          style={{
            display: 'flex',
            gap: '0.5em',
            opacity: '0.7',
            alignItems: 'center',
          }}
        >
          You're all caught up!
          <CheckCircleOutline />
        </Typography>
      </ListItem>
    );
  };

  const ListNotificationItems: FC<{
    notifications: AlertNotificationDto[];
  }> = ({ notifications }) => {
    return (
      <Box style={styles}>
        <List disablePadding>
          {notifications.map((notification, i) => {
            return (
              <NotificationItem
                onClose={() => {
                  handleClose();
                }}
                key={i}
                notification={notification}
                type={notification.isRead ? 'read' : 'unread'}
                pushUpdatedNotification={(notification) => {
                  alertNotifications.value = [
                    ...alertNotifications
                      .peek()
                      .map((x) =>
                        x.id === notification.id ? notification : x
                      ),
                  ];
                }}
              />
            );
          })}
          <AllCaughtUp />
        </List>
      </Box>
    );
  };

  return (
    <div>
      <NotificationBell
        onClick={handleClick}
        alertNotifications={alertNotifications.value}
      />
      <Popover
        id={'notification-panel'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ width: '30vw', maxWidth: 497, bgcolor: 'background.paper' }}>
          <Box display="flex" p={1}>
            <Box p={1} flexGrow={1} display="flex" gridGap={'1em'}>
              <span
                style={selectedTab === 'all' ? selectedTabStyle : tabStyle}
                onClick={() => setSelectedTab('all')}
              >
                <Typography
                  color={selectedTab === 'all' ? 'primary' : 'textSecondary'}
                  variant="subtitle2"
                >
                  Alerts
                </Typography>
              </span>
              <span
                style={selectedTab === 'unread' ? selectedTabStyle : tabStyle}
                onClick={() => setSelectedTab('unread')}
              >
                <Typography
                  color={selectedTab === 'unread' ? 'primary' : 'textSecondary'}
                  variant="subtitle2"
                >
                  Unread
                </Typography>
              </span>
            </Box>
            <Box p={1}>
              <span
                style={selectedTab === 'unread' ? selectedTabStyle : tabStyle}
                onClick={markAllRead}
              >
                <Typography variant="subtitle2" color="textSecondary">
                  Mark all as read
                </Typography>
              </span>
            </Box>
          </Box>
          <Divider />
          {loadingData ? (
            <CircularProgressCentral />
          ) : selectedTab === 'all' ? (
            <ListNotificationItems notifications={alertNotifications.value} />
          ) : (
            <ListNotificationItems
              notifications={alertNotifications.value.filter((x) => !x.isRead)}
            />
          )}
        </Box>
      </Popover>
    </div>
  );
};
