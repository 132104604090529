// @ts-nocheck
import React, { Component } from 'react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  ReferenceLine,
  Bar,
  Cell,
  ComposedChart,
  Scatter,
} from 'recharts';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import * as d3 from 'd3';

class AssociatedReportsCostTimeseriesChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      axisTicks: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data.length === this.props.data.length &&
      prevProps.domain[0] === this.props.domain[0] &&
      prevProps.domain[1] === this.props.domain[1]
    )
      return;

    this.updateTimeseriesData(this.props.data);
  }

  componentDidMount() {
    this.updateTimeseriesData(this.props.data);
  }

  updateTimeseriesData = (data) => {
    // map in order to create copy to prevent changes in base view caused by object reference
    const sortedData = data.map((d) => d).sort((a, b) => a.time - b.time);
    const ticksArr = this.getTicks(sortedData);
    this.setState({ data: sortedData, axisTicks: ticksArr });
  };

  DataCell = (value, index) => {
    return <Cell key={`cell-${index}`} fill="#FF5722" width={1} />;
  };

  getTicks = (data) => {
    if (!data || !data.length) return [];

    const scale = d3.scaleTime().domain(this.props.domain).range([0, 1]);
    const ticks = scale.ticks(d3.timeMonth, 1);

    return ticks.map((entry) => +entry);
  };

  render() {
    const { data, axisTicks } = this.state;
    const associatedReportsData =
      data &&
      data
        .filter((d) => d.value1)
        .map((d) => {
          const dCopy = Object.assign(d, {});
          dCopy.date = moment(d.date).valueOf();
          return dCopy;
        });
    const tickStyle = {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
    };
    return data ? (
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={associatedReportsData}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <Tooltip
            labelFormatter={(label) => `${moment(label).format('DD MMM YYYY')}`}
            formatter={(value) => [
              `£${value.toLocaleString()}`,
              'Settlement Amount',
            ]}
            cursor={{ stroke: '#7f8c8d', strokeWidth: 1 }}
          />
          <XAxis
            dataKey="date"
            type="number"
            scale="time"
            domain={['auto', 'auto']}
            tickFormatter={(timestamp) => moment(timestamp).format('MMM YYYY')}
            tick={tickStyle}
            allowDataOverflow
            ticks={axisTicks}
          />
          <YAxis dataKey="value1" allowDataOverflow tick={tickStyle} />
          <ReferenceLine y={0} stroke="#000" />
          <Bar
            dataKey="value1"
            minPointSize={3}
            background={false}
            isAnimationActive={false}
            maxBarSize={1}
          >
            {data.map((value, index) => this.DataCell(value, index))}
          </Bar>
          <Scatter data={associatedReportsData} isAnimationActive={false}>
            {data.map((point, index) => this.DataCell(point, index))}
          </Scatter>
        </ComposedChart>
      </ResponsiveContainer>
    ) : null;
  }
}

AssociatedReportsCostTimeseriesChart.propTypes = {
  data: PropTypes.array,
  domain: PropTypes.array.isRequired,
};

export default AssociatedReportsCostTimeseriesChart;
