import React, { useState } from 'react';
import {
  withStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InfoDialog from '../dialog/InfoDialog';
import PropTypes from 'prop-types';

const styles = {
  dialogActions: {
    display: 'flex',
    justifyContent: 'end',
    padding: '10px 0',
  },
  button: {
    marginLeft: 10,
  },
};

const DEFAULT_FORM_ERROR_TEXT =
  'The form could not be submitted. Please, fill the required fields.';

const AddEntityDialog = ({
  classes,
  buttonName,
  title,
  addFunc,
  validateFunc,
  onClose,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [formValidationError, setFormValidationError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const close = () => {
    setOpen(false);
    onClose();
  };

  const submit = async (event) => {
    event.preventDefault();
    const validationResult = validateFunc();
    if (validationResult.valid) {
      await addFunc();
      close();
    } else {
      setErrorText(validationResult.text);
      setFormValidationError(true);
    }
  };

  const getErrorText = () => (errorText ? errorText : DEFAULT_FORM_ERROR_TEXT);

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="contained" color="primary">
        <AddIcon />
        {buttonName}
      </Button>
      <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <form onSubmit={submit}>
            {children}
            <div className={classes.dialogActions}>
              <Button
                type="submit"
                onClick={submit}
                color="primary"
                variant="contained"
              >
                Submit
              </Button>
              <Button
                onClick={close}
                color="primary"
                variant="outlined"
                className={classes.button}
              >
                Cancel
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {formValidationError && (
        <InfoDialog
          open={formValidationError}
          onClose={() => setFormValidationError(false)}
          text={getErrorText()}
        />
      )}
    </>
  );
};

AddEntityDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonName: PropTypes.string,
  title: PropTypes.string,
  addFunc: PropTypes.func.isRequired,
  validateFunc: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

AddEntityDialog.defaultProps = {
  buttonName: 'Add item',
  title: 'Add item',
  onClose: () => {},
};

export default withStyles(styles)(AddEntityDialog);
