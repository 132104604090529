import React, { useState } from 'react';
import { Popover, IconButton, Typography, withStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PropTypes from 'prop-types';

const styles = {
  activatorButton: {
    color: '#7B7B7B',
  },
  content: {
    padding: 16,
    maxWidth: 400,
  },
};

const AdvancedSearchHelp = ({ classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        className={classes.activatorButton}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <HelpOutlineIcon fontSize="small" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography variant="body2" className={classes.content}>
          The search bar supports advanced queries with logical operators (AND,
          OR, NOT) and parentheses.
          <br />
          For example, &quot;tree AND NOT street AND (car OR vehicle)&quot;.
        </Typography>
      </Popover>
    </>
  );
};

AdvancedSearchHelp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdvancedSearchHelp);
