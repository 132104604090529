// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClientComboBox from '../combobox/client-combo-box';
import API from '../../services/ApiInstance';
import apiRoutes from '../../services/ApiRoutes';
import ValidationService, {
  VALIDATION_ERROR_TEXT,
} from '../../services/authentication/ValidationService';
import InfoDialog from '../dialog/InfoDialog';

const styles = {
  button: {
    float: 'right',
    marginBottom: '1.5em',
  },
  rightIcon: {
    marginRight: '0.4em',
  },
  roleSelect: {
    minWidth: 250,
  },
  formControl: {
    margin: '0 0 10px 0',
  },
};

class NewUserDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      role: '',
      orgs: [],
      username: '',
      formErrors: false,
      errorText: '',
    };
    this.validator = new ValidationService();
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, role: '', orgs: [] });
  };

  handleChange = (prop) => (e) => {
    const newState = {};
    newState[prop] = e.target.value;
    this.setState(newState);
  };

  onOrgsChange = (event, values) => {
    this.setState({ orgs: values });
  };

  isUsernameValid = () => {
    const username = this.state.username;
    return (
      !username ||
      this.validator.validate(ValidationService.validators.username(username))
    );
  };

  isEmailValid = () => {
    const email = this.state.email;
    return (
      !email ||
      this.validator.validate(ValidationService.validators.email(email))
    );
  };

  submitUser() {
    const newUser: User = {
      username: this.state.username,
      email: this.state.email,
      role: this.state.role.roleEnum,
      organisations: this.state.orgs,
    };

    const isValid = this.validator.validate(
      ValidationService.validators.username(newUser.username),
      ValidationService.validators.required(newUser.role),
      ValidationService.validators.required(newUser.email),
      ValidationService.validators.email(newUser.email),
      ValidationService.validators.arrayNotEmpty(newUser.organisations)
    );
    if (isValid) {
      try {
        API.post<User, User>(apiRoutes.addUser, newUser).then(() => {
          this.handleClose();
          this.props.onSuccess();
        });
      } catch (e) {
        console.log('Error: ', e);
      }
    } else {
      this.setState({ formErrors: true });
      this.setState({
        errorText: 'Every field is required. Please review.',
      });
    }
  }

  render() {
    const { classes, roles } = this.props;
    const { role, open, orgs, formErrors, errorText } = this.state;
    return (
      <>
        <Button
          onClick={this.handleClickOpen}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <AddIcon className={classes.rightIcon} />
          Add New User
        </Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Add New User</DialogTitle>
          <DialogContent>
            <FormControl
              variant="outlined"
              className={`${classes.roleSelect} ${classes.formControl}`}
            >
              <InputLabel id="user-role-label">Role *</InputLabel>
              <Select
                labelId="user-role-label"
                label="Role"
                value={role}
                onChange={this.handleChange('role')}
              >
                {roles &&
                  roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              required
              autoFocus
              variant="outlined"
              margin="dense"
              id="username"
              label="Username"
              type="text"
              fullWidth
              onChange={this.handleChange('username')}
              error={!this.isUsernameValid()}
              helperText={
                this.isUsernameValid() ? null : VALIDATION_ERROR_TEXT.Username
              }
              className={classes.formControl}
            />
            <ClientComboBox
              value={orgs}
              onChange={this.onOrgsChange}
              multiple
              id="tags-outlined"
              filterSelectedOptions
              customLabel="Organisations *"
              className={classes.formControl}
            />
            <TextField
              required
              error={!this.isEmailValid()}
              variant="outlined"
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              onChange={this.handleChange('email')}
              className={classes.formControl}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.submitUser()}
              color="primary"
              variant="contained"
            >
              Add
            </Button>
            <Button
              onClick={this.handleClose}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {formErrors && (
          <InfoDialog
            open={formErrors}
            onClose={() => this.setState({ formErrors: false })}
            text={errorText}
          />
        )}
      </>
    );
  }
}
NewUserDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default withStyles(styles)(NewUserDialog);
