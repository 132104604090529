export const CARD_TYPE = 'DnDCard';
export const UNMAPPED_ITEMS_POOL = 'Parsed Headers';
export const EXTRA_ITEMS_POOL = 'EXTRAS';

export const ENTITY_TYPES = {
  Reports: 1,
  Locations: 2,
  Facilities: 4,
  AssociatedReports: 8,
  Organisations: 16,
  ReportType: 32,
} as const;

type EntityKey = keyof typeof ENTITY_TYPES;
export type EntityEnums = (typeof ENTITY_TYPES)[EntityKey] & number;

export const ENTITY_NAMES = {
  Reports: 'Reports',
  Locations: 'Locations',
  Facilities: 'Facilities',
  AssociatedReports: 'Associated reports',
  Organisations: 'Organisations',
};

export const ITEMS = [
  { id: 1, name: 'Item 1', card: UNMAPPED_ITEMS_POOL },
  { id: 2, name: 'Item 2', card: UNMAPPED_ITEMS_POOL },
  { id: 3, name: 'Item 3', card: UNMAPPED_ITEMS_POOL },
  { id: 4, name: 'Item 4', card: 'Location' },
  { id: 5, name: 'Item 5', card: UNMAPPED_ITEMS_POOL },
  { id: 6, name: 'Item 6', card: UNMAPPED_ITEMS_POOL },
  { id: 7, name: 'Item 7', card: UNMAPPED_ITEMS_POOL },
  { id: 8, name: 'Item 8', card: UNMAPPED_ITEMS_POOL },
];
