import React, { FC, useState } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import ConfirmDialog from '../dialog/ConfirmDialog';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import { toast } from 'react-toastify';
import { AliasMapDto } from '../../api/apiDtos';

interface Props {
  aliasMap: AliasMapDto;
  onDelete: () => void;
}

const DeleteAliasDialog: FC<Props> = ({ aliasMap, onDelete }) => {
  const [open, setOpen] = useState(false);

  const deleteAliasMap = async () => {
    const url = `${ApiRoutes.deleteAlias}?id=${aliasMap.id}`;
    await api.del(url);
    toast.success('Aliases deleted');
    setOpen(false);
    onDelete();
  };

  return (
    <>
      <Tooltip title="Delete aliases">
        <IconButton
          aria-label="Delete aliases"
          color="primary"
          onClick={() => setOpen(true)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={deleteAliasMap}
        title="Delete aliases"
        confirm="Delete"
      >
        <span>{`Are you sure you wish to delete aliases for '${aliasMap.name}'?`}</span>
      </ConfirmDialog>
    </>
  );
};

export default DeleteAliasDialog;
