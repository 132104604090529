/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useState, useContext, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { useTrackerStyles } from './styles';
import { dateFormats } from '../../helpers/date-helper';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import { isObjectEmpty } from '../../helpers/data-helper';
import moment from 'moment';
import MitigationActivityTracker from './MitigationActivityTracker';
import TargetsTracker from './TargetsTracker';
import PreventiveActionsGuidance from '../widgets/preventive-actions-guidance/PreventiveActionsGuidance';
import { CHART_FORMATS } from './ChartFormatToggle';
import TrackerPeriodSelector, { PERIODS } from './TrackerPeriodSelector';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import { CHART_DATA_FORMATS } from './ChartDataVisualisationTypeToggle';
import { selectedAlert } from '../../signals/selectedAlert';
import CircularProgressCentral from '../common/CircularProgressCentral';
import { useEffectAsync } from '../../hooks/useEffectAsync';
import IconButton from '@material-ui/core/IconButton';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import ShowChart from '@material-ui/icons/ShowChart';
import { AlertsConfigContext } from '../../context/AlertConfigContextProvider';
import { generateOnePagePdf } from '../../helpers/pdf-helper';
import { AlertTypes, ROUTES } from '../../constants';
import Grid from '@mui/material/Grid';
import TimeServiceProvider from '../../services/TimeService';
import AddAlertOutlined from '@material-ui/icons/AddAlertOutlined';
import { Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import {
  AddFilter,
  ClearFilters,
  ConstructFilter,
} from '../../signals/filterService';
import { useHistory } from 'react-router-dom';

const trackerContentId = 'tracker-content';
const DEFAULT_TRACKER_DATA = {
  periodEndDate: moment(),
  period: PERIODS.Quarter,
  targetsChartFormat: CHART_FORMATS.TotalCounts,
  mitigationChartFormat: CHART_FORMATS.TotalCounts,
  chartData: {},
  preventiveActions: [],
  stepDays: 1,
  dataVisualisationType: CHART_DATA_FORMATS.Actual,
};

const formatDate = (dateString) => {
  return moment(new Date(dateString)).format(dateFormats.compact);
};

const processChartData = (data) => {
  const percentagesChartData = Object.entries(data.percentagesChartData).map(
    ([date, tick]) => ({ ...tick, date: formatDate(date) })
  );
  const totalCountsChartData = Object.entries(data.totalCountsChartData).map(
    ([date, tick]) => ({ ...tick, date: formatDate(date) })
  );
  return {
    ...data,
    totalCountsChartData,
    percentagesChartData,
  };
};
const TrackerViewer = (props) => {
  const timeService = TimeServiceProvider();
  const history = useHistory();
  const [trackerData, setTrackerData] = useState({ ...DEFAULT_TRACKER_DATA });
  const { setAlertsConfig } = useContext(AlertsConfigContext);
  const [loadingChartsData, setLoadingChartsData] = useState(true);
  const [pdfExportHideButton, setPdfExportHideButton] = useState(false);

  const classes = useTrackerStyles();
  const { tabValue, item, onAddAction, onRemoveAction, onUpdateTargetValue } =
    props;

  async function assignEndPeriodDate() {
    return await api.get(ApiRoutes.latestDateOccurredReport);
  }

  const exportToPdf = () => {
    setPdfExportHideButton(true);
    const element = document.getElementById('tracker-content');
    if (element) {
      generateOnePagePdf(element, item?.name || 'default-name', 'portrait')
        .then(() => {
          setPdfExportHideButton(false);
        })
        .catch((err) => {
          setPdfExportHideButton(false);
          toast.error('Error generating PDF', err);
        });
    } else {
      setPdfExportHideButton(false);
      toast.error('Element not found');
    }
  };

  const exportTrackerReports = async () => {
    try {
      const url = `${ApiRoutes.exportTrackerReports}?trackerId=${item.id}`;
      await api.downloadFile(url, `Tracker reports. ${item.name}.xlsx`);
    } catch (error) {
      console.log('Failed to export reports: ' + error);
    } finally {
    }
  };

  function CreateAlert() {
    setAlertsConfig(AlertTypes.create);
  }
  useEffect(() => {
    const getTrackerData = async () => {
      let endDate = '';

      if (selectedAlert.value) {
        endDate = await assignEndPeriodDate();
      }

      const endDateToUse =
        selectedAlert.value && endDate ? moment(endDate) : moment();

      const chartData = await getChartData(
        item.id,
        endDateToUse,
        DEFAULT_TRACKER_DATA.stepDays,
        DEFAULT_TRACKER_DATA.dataVisualisationType
      );
      const preventiveActions = await api.get(
        ApiRoutes.getTrackerPreventiveActions,
        {
          params: { trackerId: item.id },
        }
      );

      setTrackerData({
        ...DEFAULT_TRACKER_DATA,
        periodEndDate: endDateToUse,
        chartData,
        preventiveActions,
        id: item.id,
      });
    };
    getTrackerData();
  }, [item]);

  const getChartData = async (
    trackerId,
    endDate,
    stepDays,
    dataVisualisationType
  ) => {
    setLoadingChartsData(true);
    const response = await api.get(ApiRoutes.getChartData, {
      params: {
        trackerId,
        endDate: moment(endDate).toJSON(),
        period: trackerData.period,
        stepDays: stepDays,
        dataVisualisationType: dataVisualisationType,
      },
    });
    var result = processChartData(response);
    if (result) {
      return result;
    }
  };

  //allows good UI experience loading when data is being pulled through
  useEffect(() => {
    if (trackerData.chartData.targetsData) {
      setLoadingChartsData(false);
    }
  }, [trackerData]);

  const refreshCurrentTrackerChartData = async () => {
    const chartData = await getChartData(
      item.id,
      trackerData.periodEndDate,
      trackerData.stepDays,
      trackerData.dataVisualisationType
    );
    setTrackerData((prevState) => ({
      ...prevState,
      chartData,
    }));
  };

  useEffect(() => {
    if (trackerData.period > 0) {
      refreshCurrentTrackerChartData();
    }
  }, [trackerData.period]);

  useEffect(() => {
    if (trackerData.stepDays > 0) {
      refreshCurrentTrackerChartData();
    }
  }, [trackerData.stepDays]);

  const handleTargetValueUpdate = (updatedTarget) => {
    onUpdateTargetValue(updatedTarget, refreshCurrentTrackerChartData);
  };

  const handleChangePeriodEndDate = async (date) => {
    const chartData = await getChartData(item.id, date);
    setTrackerData((prevState) => ({
      ...prevState,
      periodEndDate: date,
      chartData,
    }));
  };

  const handleChangeTrackerData = (field) => (value) => {
    setTrackerData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const displayInDiscover = () => {
    const localEndDate = trackerData.periodEndDate.clone();
    const periodStartDate =
      trackerData.period === 3
        ? localEndDate.subtract(1, 'year')
        : trackerData.period === 2
        ? localEndDate.subtract(1, 'quarter')
        : trackerData.period === 1
        ? localEndDate.subtract(1, 'month')
        : null;

    const startDate = moment(periodStartDate, 'DD/MM/YYYY', true);
    const endDate = moment(trackerData.periodEndDate, 'DD/MM/YYYY', true);

    timeService.setTime([startDate, endDate.valueOf()]);
    ClearFilters();
    item.filters.forEach((filter) => {
      const names = filter.names.split('|');
      const ids = filter.ids.split('|');
      for (let i = 0; i < names.length; i++) {
        const name = names[i];
        let id = ids[i];
        if (!id) {
          id = '';
        }
        AddFilter(ConstructFilter(name, filter.typeCode, id));
      }
    });
    history.push(ROUTES.Dashboard);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              className={`${classes.periodSelector} ${classes.periodSelectorInput}`}
              label="Period end date"
              value={trackerData.periodEndDate}
              format="DD MMM YYYY"
              onChange={handleChangePeriodEndDate}
              inputVariant="outlined"
            />
          </MuiPickersUtilsProvider>
          <TrackerPeriodSelector
            className={`${classes.periodSelector} ${classes.periodSelectorInput}`}
            value={trackerData.period}
            onChange={handleChangeTrackerData('period')}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridButtonLayout}>
          <Tooltip title={'Export to PDF'}>
            <IconButton
              className={classes.iconCircleButton}
              onClick={exportToPdf}
            >
              <PictureAsPdfIcon className={classes.iconStyle} />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Display in Discover'}>
            <IconButton
              className={classes.iconCircleButton}
              onClick={displayInDiscover}
            >
              <ShowChart className={classes.iconStyle} />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Export Data'}>
            <IconButton
              className={classes.iconCircleButton}
              onClick={exportTrackerReports}
            >
              <GetAppIcon className={classes.iconStyle} />
            </IconButton>
          </Tooltip>
          {tabValue !== '1' ? (
            <Tooltip title={'New Alert'}>
              <IconButton
                className={classes.iconCircleButton}
                onClick={CreateAlert}
              >
                <AddAlertOutlined className={classes.iconStyle} />
              </IconButton>
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>
      {!loadingChartsData ? (
        <>
          <div id={trackerContentId}>
            <Paper className={classes.chartWrapper}>
              <TargetsTracker
                targets={item.targets}
                targetsData={trackerData.chartData.targetsData}
                period={trackerData.period}
                onChangePeriod={handleChangeTrackerData('period')}
                onUpdateTargetValue={handleTargetValueUpdate}
                chartFormat={trackerData.targetsChartFormat}
                onChangeChartFormat={handleChangeTrackerData(
                  'targetsChartFormat'
                )}
              />
            </Paper>
            <Paper className={classes.chartWrapper}>
              <MitigationActivityTracker
                hideButton={pdfExportHideButton}
                chart={trackerData.chartData}
                actions={item.actions}
                onSubmitNewAction={onAddAction}
                onRemoveAction={onRemoveAction}
                chartFormat={trackerData.mitigationChartFormat}
                onChangeChartFormat={handleChangeTrackerData(
                  'mitigationChartFormat'
                )}
                onChangeDataPeriod={handleChangeTrackerData('stepDays')}
                onChangeDataValueType={handleChangeTrackerData(
                  'dataVisualisationType'
                )}
                stepDays={trackerData.stepDays}
                dataVisualisationType={trackerData.dataVisualisationType}
              />
            </Paper>
          </div>
          <Paper className={classes.chartWrapper}>
            <PreventiveActionsGuidance
              limit={5}
              initialData={trackerData.preventiveActions ?? []}
            />
          </Paper>
        </>
      ) : (
        <div className={classes.alignCenter}>
          <CircularProgressCentral />
        </div>
      )}
    </>
  );
};

TrackerViewer.propTypes = {
  tabValue: PropTypes.string,
  item: PropTypes.object,
  onAddAction: PropTypes.func,
  onRemoveAction: PropTypes.func,
  onUpdateTargetValue: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TrackerViewer;
