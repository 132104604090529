//@ts-nocheck
import React, { Component, FC, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  withStyles,
  CardActions,
  WithStyles,
  createStyles,
} from '@material-ui/core';
import { ZoomOutMap as ZoomOutMapIcon } from '@material-ui/icons';
import DashboardModal from '../dashboard/DashboardModal';
import PdfCreator from '../PdfCreator';
import IDService from '../../services/IdService';
import HelpButton from '../help-button/help-button';
import { getSettings } from '../../helpers/data-helper';
import {
  ReportsByOrganisationsDefaultSettings,
  ReportsByOrganisationsDefaultSettingsType,
  ReportsByTypesDefaultSettings,
  ReportsByTypesDefaultSettingsType,
  WidgetSettings,
} from './widgets-default-settings';
import { widgetPropOptions } from '../widgets-manager-page/widgets-management-constants';
import { WidgetCode } from './widgetCodes';
import TimeServiceProvider from '../../services/TimeService';
import ReportsByBrandCard from './ReportsByBrandCard';
import ReportsByTypeCard from './ReportsByTypeCard';
import { getReportWidgetData } from '../../services/WidgetDataService';
import CircularProgressCentral from '../common/CircularProgressCentral';
import PropTypes from 'prop-types';
import UserPreferenceService from '../../services/UserPreferenceService';
import { logDataFetchError, logResponseParseError } from '../../services/utils';
import { ReportByTypeWidgetDto } from '../../api/originalMappings';
import { ReportByTypeWidgetDtoSchema } from '../../api/zodSchemas';
import { filters } from '../../signals/filterService';

const styles = createStyles({
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
  modalWrap: {
    height: 400,
  },
});

interface Props extends WithStyles<typeof styles> {
  reportsByOrganisationsProps: any;
  reportsByTypesProps: any;
}

const ReportSortWidget: FC<Props> = ({
  classes,
  reportsByOrganisationsProps,
  reportsByTypesProps,
}) => {
  const userPreferenceService = new UserPreferenceService();
  const timeService = TimeServiceProvider();
  const [showModal, setShowModal] = useState(false);
  const [sortType, setSortType] = useState(1);
  const [brandId, setBrandId] = useState(null);
  const [data, setData] = useState<ReportByTypeWidgetDto>(null);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    setIsLoading(true);
    getReportWidgetData(WidgetCode.reportsByOrganisationAndTypes)
      .then((res) => {
        if (!res) return {};
        //here zod is used to do a runtime check whether the parsed res from api is the
        //correct format
        const schemaMatch = ReportByTypeWidgetDtoSchema.safeParse(res);
        if (schemaMatch.success) {
          setData(schemaMatch.data);
        } else {
          //@ts-ignore
          logResponseParseError(schemaMatch.error);
        }
      })
      .catch((err) =>
        logDataFetchError(err, 'Reports by Organisation And Types')
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(function getFilteredChartData(){
    getData()
  },[filters.value])

  const getValue = (orgValue: WidgetSettings, typeValue: WidgetSettings) => {
    return sortType === 1 ? orgValue : typeValue;
  };

  const getPdfFileName = (title) => title + '.pdf';

  const getActionText = (
    orgSettings: WidgetSettings,
    typeSettings: WidgetSettings
  ) => {
    const { title } = getValue(typeSettings, orgSettings); //inversed
    return 'Show ' + title;
  };

  const setModal = (showModal: boolean) => () => setShowModal(showModal);

  const handleBrandChange = (event: any) => {
    setBrandId(event.target.value);
  };

  const changeSort = () => {
    let _sortType = sortType + 1;
    if (_sortType === 3) {
      _sortType = 1;
    }
    setSortType(_sortType);
  };

  const getBrandOptions = (typeSettings: ReportsByTypesDefaultSettingsType) => {
    //@ts-ignore
    const brandOptionsSource =
      typeSettings.organisationOrFacility === widgetPropOptions.organisation
        ? data.organisationChartData
        : data.facilityChartData;
    return brandOptionsSource.map((item: any) => ({
      id: item.id,
      name: item.name,
      count: item.count,
    }));
  };

  useEffect(function onLoad() {
    getData();
    const savedSortType = userPreferenceService.get('ReportSortWidgetSortType');
    if (savedSortType !== null) {
      setSortType(savedSortType);
    }
    const savedBrandId = userPreferenceService.get('ReportSortWidgetBrandId');
    if (savedBrandId !== null) {
      setBrandId(savedBrandId);
    }
  }, []);

  useEffect(function timeServiceListener() {
    timeService.subscribe(getData);

    return () => {
      timeService.unsubscribe(getData);
    };
  }, []);

  useEffect(
    function changeSortTypeOrBrandId() {
      if (sortType) {
        userPreferenceService.save('ReportSortWidgetSortType', sortType);
      }

      if (brandId) {
        userPreferenceService.save('ReportSortWidgetBrandId', brandId);
      }
    },
    [sortType, brandId]
  );

  if (isLoading || !data) {
    return <CircularProgressCentral />;
  }

  const orgSettings: ReportsByOrganisationsDefaultSettingsType = getSettings(
    reportsByOrganisationsProps,
    ReportsByOrganisationsDefaultSettings
  );
  const typeSettings: ReportsByTypesDefaultSettingsType = getSettings(
    reportsByTypesProps,
    ReportsByTypesDefaultSettings
  );

  const { itemsLabel: typeItemsLabel } = typeSettings;
  const elementId = IDService.Generate();

  const { title, info } = getValue(orgSettings, typeSettings);

  const actionText = getActionText(orgSettings, typeSettings);
  const pdfFileName = getPdfFileName(title);

  const brandOptions = getBrandOptions(typeSettings);
  brandOptions[0] = brandOptions[0] || {
    id: null,
    name: 'No data available',
    count: 0,
  };
  const selectedBrandId = brandId || brandOptions[0].id;

  const renderCardContent = () => {
    if (sortType === 1) {
      return <ReportsByBrandCard data={data} settings={orgSettings} />;
    } else if (selectedBrandId) {
      return (
        <ReportsByTypeCard
          data={data}
          settings={typeSettings}
          brandId={selectedBrandId}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Card variant="outlined" className={classes.card}>
        <CardHeader
          action={
            <div className={'flex'}>
              {sortType === 2 && (
                <div style={{ marginRight: '1em', marginTop: 6 }}>
                  <FormControl>
                    <Select
                      onChange={handleBrandChange}
                      value={selectedBrandId}
                    >
                      {brandOptions.map((brand) => (
                        <MenuItem key={brand.id} value={brand.id}>
                          {`${brand.name} (${brand.count} ${typeItemsLabel})`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}

              <Button color="primary" size="small" onClick={changeSort}>
                {actionText}
              </Button>
              <HelpButton title={title} info={info} />
              <PdfCreator targetElementId={elementId} fileName={pdfFileName} />
              <IconButton onClick={setModal(true)}>
                <ZoomOutMapIcon fontSize="small" />
              </IconButton>
            </div>
          }
        />
        <CardContent id={elementId} className={classes.cardContent}>
          {renderCardContent()}
        </CardContent>
        <CardActions />
      </Card>
      <DashboardModal
        handleClose={setModal(false)}
        title={title}
        open={showModal}
      >
        <div className={classes.modalWrap}>
          {renderCardContent()}
          <PdfCreator fileName={pdfFileName} />
        </div>
      </DashboardModal>
    </>
  );
};

export default withStyles(styles)(ReportSortWidget);
