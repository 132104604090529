import { FC, useRef } from 'react';
import HighchartsReact, {
  HighchartsReactProps,
} from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import React from 'react';
import { RootCauseClassificationAggregationDto } from '../../../api/originalMappings';

HC_more(Highcharts);

interface Props extends HighchartsReactProps {
  data: RootCauseClassificationAggregationDto[];
}

const COMET = [
  'Communication',
  'Operating Environment',
  'Management',
  'Equipment',
  'Training',
];

const createHighchartsData = (
  chartData: RootCauseClassificationAggregationDto[]
): any[] => {
  const keys = Array.from(new Set(chartData.map(Object.keys).flat())).filter(
    (x) => x !== 'levelOne'
  );

  // Map over the keys array to create a new array of objects.
  const seriesDataKeys = keys.map((name) => ({
    name,
  }));

  const formatReportCounts = (
    chartData: RootCauseClassificationAggregationDto[],
    orgName: string
  ): any[] => {
    let newArr = [];
    chartData.forEach((data) => {
      newArr.push(data[orgName] ?? 0);
    });

    return newArr;
  };

  return seriesDataKeys.map((x) => ({
    ...x,
    data: formatReportCounts(chartData, x.name),
  }));
};

const RootCauseBreakdownsBaseChart: FC<Props> = ({
  data,
  ...highChartsProps
}) => {
  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      polar: true,
      inverted: true,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    pane: {
      size: '100%',
      innerSize: '20%',
      endAngle: 270,
    },
    xAxis: {
      categories: data.map((x) => x.levelOne),
      lineWidth: 0,
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        formatter: function () {
          return this.value + '%';
        },
      },
      lineWidth: 0,
      tickInterval: 5,
      reversedStacks: false,
      endOnTick: true,
      showLastLabel: true,
      gridLineWidth: 0,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.15,
        borderRadius: '50%',
      },
    },
    legend: {
      itemStyle: {
        fontSize: '10px',
      },
      y: 10,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      floating: false,
      backgroundColor: '#FFFFFF',
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.series.name + '</b>: ' + this.y + '%';
      },
    },
    series: createHighchartsData(data),
  };

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      ref={chartComponentRef}
      {...highChartsProps}
    />
  );
};

export default RootCauseBreakdownsBaseChart;
