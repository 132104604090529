import React, { FC, useRef } from 'react';
import { ReportsByDatesDefaultSettings } from '../widgets/widgets-default-settings';
import NoDataText from '../common/NoDataText';
import DateService from '../../services/date-service';
import { OTHERS } from '../../constants';
import HighchartsReact, {
  HighchartsReactProps,
} from 'highcharts-react-official';
import {
  ChartDataDto,
  ReportDateAggregationDto,
} from '../../api/originalMappings';
import Highcharts from 'highcharts';

const aggregateMinorReportTypes = (
  data: ReportDateAggregationDto[],
  aggregationThreshold: number
) => {
  return data.map((item) => {
    const processed: ReportDateAggregationDto = { date: item.date };

    if (item.typeCounts.length > aggregationThreshold) {
      const sorted = item.typeCounts.sort((a, b) => b.count - a.count);
      processed.typeCounts = sorted.slice(0, aggregationThreshold);
      const othersCount = sorted
        .slice(aggregationThreshold)
        .reduce((sum, current) => sum + current.count, 0);
      processed.typeCounts.push({ type: OTHERS, count: othersCount });
    } else {
      processed.typeCounts = item.typeCounts;
    }

    return processed;
  });
};

const createChartData = (data) =>
  data.map((reportsBucket) => {
    const chartPoint = {
      date: DateService.ParseMMMMYY(new Date(reportsBucket.date)),
    };
    reportsBucket.typeCounts.forEach((i) => {
      const key = i.type;
      chartPoint[key] = i.count;
    });
    return chartPoint;
  }, []);

const createHighchartsSeriesData = (
  chartData: any[]
): Highcharts.SeriesBarOptions[] => {
  const keySet = new Set(
    chartData.flatMap(Object.keys).filter((key) => key !== 'date')
  );
  const keys = Array.from(keySet);

  // Map over the keys array to create a new array of objects.
  const seriesDataKeys = keys.map((key) => ({
    name: key,
  }));

  const formatReportTypeCounts = (
    chartData: ChartDataDto[],
    key: string
  ): number[] => {
    return chartData.map((x) => (x[key] ? x[key] : 0));
  };

  return seriesDataKeys.map((x) => ({
    ...x,
    type: 'bar',
    data: formatReportTypeCounts(chartData, x.name),
  }));
};

interface Props extends HighchartsReactProps {
  data: ReportDateAggregationDto[];
  aggregationEnabled: boolean;
  aggregationThreshold: number;
  legend?: boolean;
  axisY?: string;
}

export const ReportDatesByTypeChart: FC<Props> = ({
  data,
  aggregationEnabled,
  aggregationThreshold,
  ...rest
}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
 
  const renderBarChart = (chartData: any[]) => {
    const filteredChartData = chartData.filter(
      (x) => Object.keys(x).length > 1
    );

    const textAxisY = ReportsByDatesDefaultSettings.axisY;

    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'bar',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: 'Reports By Type',
      },
      xAxis: {
        categories: filteredChartData.map((y) => y.date),
        title: {
          text: null,
        },
        gridLineWidth: 1,
        lineWidth: 0,
      },
      yAxis: {
        title: {
          text: 'Number of Reports',
        },
        labels: {
          overflow: 'justify',
        },
        gridLineWidth: 0,
        type: 'logarithmic'
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            formatter: function() {
              if (this.y === 0) {
                return null;
              } else {
                return this.y;
              }
            }
          }
        },
        bar: {
          borderRadius: '50%',
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      legend: {
        enabled: true,
      },
      series: createHighchartsSeriesData(filteredChartData),
    };

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        ref={chartComponentRef}
        {...rest}
      />
    );
  };

  const textNoData = ReportsByDatesDefaultSettings.noDataLabel;

  const processedData = aggregationEnabled
    ? aggregateMinorReportTypes(data, aggregationThreshold)
    : data;

  const chartData = createChartData(processedData);
  const hasData = data && data.some((d) => d.typeCounts.length > 0);

  return hasData ? (
    renderBarChart(chartData)
  ) : (
    <NoDataText label={textNoData} />
  );
};
