// @ts-nocheck
import React from 'react';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import API from '../../services/ApiInstance';
import apiRoutes from '../../services/ApiRoutes';
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { trackPage } from '../../services/analytics/AnalyticsService';
import UploadMapper from './upload-mapper/upload-mapper';
import { UNMAPPED_ITEMS_POOL, ITEMS } from './upload-mapper/constants';
import ClientComboBox from '../combobox/client-combo-box';
import SendIcon from '@material-ui/icons/Send';
import UserPreferenceService from '../../services/UserPreferenceService';
import FileDropzone from './FileDropzone';
import UploadResultViewer from './UploadResultViewer';
import { ENTITY_TYPES } from './upload-mapper/constants';
import EntitiesSelector from './EntitiesSelector';
import InfoDialog from '../dialog/InfoDialog';
import { appendFlagEnum } from '../../helpers/form-data-helper';

const userPreferenceService = new UserPreferenceService();
const acceptedExtensions = ['csv', 'xlsx', 'xls'];
const acceptedMimeTypes =
  'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const TranslateSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#d94936',
    },
    '&$checked + $track': {
      backgroundColor: '#d94936',
    },
  },
  checked: {},
  track: {},
})(Switch);

function buildFileSelector(callback) {
  const acceptedExtensionsAsString = acceptedExtensions
    .map((ext) => `.${ext}`)
    .join(',');
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('accept', acceptedExtensionsAsString);
  fileSelector.onchange = callback;
  fileSelector.onclick = function () {
    this.value = null;
  };
  return fileSelector;
}

const styles = {
  paper: {
    margin: '0 0 1em 0',
    padding: '1em',
    textAlign: 'center',
    minHeight: '500px',
    height: 'auto',
  },
  translateSwitch: {
    marginLeft: '6em',
    marginTop: '0.5em',
    display: 'flex',
    justifyContent: 'center',
  },
  uploadText: {
    fontSize: '0.9em',
    textAlign: 'center',
    marginBottom: '2em',
    marginTop: '0.8em',
  },
  uploadErrorText: {
    fontSize: '0.9em',
    textAlign: 'center',
    marginBottom: '4em',
    marginTop: '0.8em',
    color: 'red',
  },
  uploadimg: {
    marginTop: '1.5em',
  },
  header: {
    marginBottom: '0.5em',
    textAlign: 'center',
  },
  subheader: {
    marginBottom: '0.5em',
    fontSize: '1em',
    textAlign: 'center',
  },
  table: {
    display: 'table',
    width: '70%',
    margin: 'auto',
  },
  row: {
    display: 'table-row',
  },
  cellCenter: {
    display: 'table-cell',
    textAlign: 'center',
  },
  cellLeft: {
    display: 'table-cell',
    textAlign: 'left',
  },
  spinner: {
    marginBottom: '100px',
    marginTop: '100px',
  },
  uploadButton: {
    margin: '0 10px',
    minWidth: 120,
  },
  buttonIcon: {
    marginRight: 5,
  },
  titleText: {
    fontSize: '1.2em',
    marginBottom: '1em',
    textAlign: 'center',
    color: '#666',
  },
  fileName: {
    fontWeight: 500,
  },
  fileDropZone: {
    margin: 'auto',
    marginBottom: 10,
  },
};

const FILE_IS_EMPTY = 'File is empty';

// export interface Item {
// 	id: number;
// 	name: string
// 	card: string
// };

// interface ReportUploadState {
// 	isUploading: boolean,
// 	isUploadingError: boolean,
// 	fileSelector: any,
// 	fileName: string,
// 	errorMessage: string,
// 	isReportChecked: boolean,
// 	shouldBeTranslated: boolean,
// 	uploadResult: any,
// 	reportFile: any,
// 	showMapper: boolean,
// 	mapperItems: Item[],
// 	client: any,
// 	defaultClient: any,
// 	selectedEntityTypes: ENTITY_TYPES[],
// 	isMappingErrorVisible: boolean
// }

class ReportUpload extends React.Component {
  // state: ReportUploadState;

  constructor(props) {
    super(props);
    trackPage('upload');
    this.state = {
      isUploading: false,
      isUploadingError: false,
      fileSelector: buildFileSelector(this.fileSelectorCallback),
      fileName: '',
      errorMessage: '',
      isReportChecked: false,
      shouldBeTranslated: false,
      uploadResult: null,
      reportFile: null,
      showMapper: false,
      mapperItems: ITEMS,
      client: null,
      defaultClient: null,
      selectedEntityTypes: [ENTITY_TYPES.Reports],
      isMappingErrorVisible: false,
    };
    this.mapperRef = React.createRef();
  }

  componentDidMount() {
    const savedClient = userPreferenceService.get('UploadClient');
    if (savedClient !== null) {
      this.setState({ defaultClient: savedClient });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.client !== prevState.client) {
      this.onClientChange();
    }
  }

  onClientChange() {
    userPreferenceService.save('UploadClient', this.state.client);
  }

  fileSelectorCallback = (e) => {
    const file = e.target.files[0];
    this.parseHeaders(file);
  };

  onSelectFile = (e) => {
    e.preventDefault();
    this.setState({
      isUploading: false,
      isUploadingError: false,
    });
    this.state.fileSelector.click();
  };

  handleUploadAnother = () => {
    this.setState({
      isUploadingError: false,
      isReportChecked: false,
    });
  };

  handleTranslate = (event) => {
    this.setState({
      shouldBeTranslated: event.currentTarget.checked,
    });
  };

  isFileAccepted = (filename) =>
    acceptedExtensions.includes(this.getFileExtension(filename));

  getFileExtension = (filename) =>
    filename ? filename.split('.').pop().toLowerCase() : '';

  onDropFile = (files) => {
    const file = files[0];
    if (file && file.name && !this.isFileAccepted(file.name)) {
      return;
    }
    this.parseHeaders(file);
  };

  parseHeaders = (file) => {
    if (!file) {
      return;
    }

    if (!this.isFileAccepted(file.name)) {
      this.setState({
        isUploading: false,
        fileName: file.name,
        errorMessage:
          'Incorrect file type. Please select a CSV or an Excel file.',
        isUploadingError: true,
        isReportChecked: false,
      });
      return;
    }

    this.setState({
      isUploading: true,
      reportFile: file,
    });

    const formData = new FormData();
    formData.append('file', file);

    const { client } = this.state;
    formData.append('clientId', client ? client.id : null);

    API.post(apiRoutes.fileParseHeaders, formData).then(
      (response) => {
        if (response === FILE_IS_EMPTY) {
          this.setState({
            errorMessage: 'Error encountered: ' + response,
            fileName: `${file.name}`,
            isUploading: false,
            isUploadingError: true,
            isReportChecked: false,
          });
        } else {
          const items = response.map((item, index) => {
            return {
              id: index,
              name: item.name,
              card: item.mappedTo ? item.mappedTo : UNMAPPED_ITEMS_POOL,
            };
          });

          this.setState({ mapperItems: items });

          this.setState({
            fileName: `${file.name}`,
            isUploading: false,
            isUploadingError: false,
            showMapper: true,
            mapperItems: items,
          });
        }
      },
      (err) => {
        this.setState({
          errorMessage: 'Error encountered: ' + err,
          fileName: `${file.name}`,
          isUploading: false,
          isUploadingError: true,
          isReportChecked: false,
        });
        console.log('Error encountered: ' + err);
      }
    );
  };

  getRules = () => {
    return this.mapperRef.current.getRules();
  };

  addRulesToFormData = (formdata, rules, name) => {
    for (let i = 0; i < rules.length; i++) {
      this.addSingleRuleToFormData(formdata, rules[i], name + '[' + i + ']');
    }
  };

  addSingleRuleToFormData = (formdata, rule, name) => {
    for (let key in rule) {
      formdata.append(name + '.' + key, rule[key]);
    }
  };

  fileProcessMappedItems = () => {
    const { reportFile, client, shouldBeTranslated, selectedEntityTypes } =
      this.state;

    if (!reportFile) return;

    if (selectedEntityTypes.length === 0) {
      this.showMappingError('Please, select at least one entity');
      return;
    }

    if (!this.mapperRef.current.isValid()) {
      this.showMappingError('Please, map all the required properties');
      return;
    }

    this.setState({
      isUploading: true,
      mapperItems: this.mapperRef.current.getItems(),
    });

    const rules = this.getRules();
    if (!rules) return;

    const formData = new FormData();
    formData.append('file', reportFile);

    this.addRulesToFormData(formData, rules, 'rules');
    formData.append('clientId', client ? client.id : null);
    formData.append('shouldBeTranslated', shouldBeTranslated);

    const valueMappingRules = this.mapperRef.current.getValueMappingRules();
    formData.append(
      'valueMappingRulesString',
      JSON.stringify(valueMappingRules)
    );

    appendFlagEnum(formData, 'entityTypes', selectedEntityTypes);

    API.post(apiRoutes.fileProcessMapped, formData).then(
      (response) => {
        if (response === FILE_IS_EMPTY) {
          this.setState({
            errorMessage: 'Error encountered: ' + response,
            fileName: `${reportFile.name}`,
            isUploading: false,
            isUploadingError: true,
            isReportChecked: false,
          });
        } else {
          const uploadResult = response;

          this.setState({
            fileName: `${reportFile.name}`,
            isUploading: false,
            isUploadingError: false,
            uploadResult,
            isReportChecked: true,
            showMapper: false,
          });
        }
      },
      (err) => {
        this.setState({
          errorMessage: 'Error encountered: ' + err,
          fileName: `${reportFile.name}`,
          isUploading: false,
          isUploadingError: true,
          isReportChecked: false,
        });
        console.log('Error encountered: ' + err);
      }
    );
  };

  showMappingError = (text) => {
    this.setState({
      errorMessage: text,
      isMappingErrorVisible: true,
    });
  };

  spinner() {
    const { classes } = this.props;
    return (
      <CircularProgress
        color="inherit"
        className={classes.spinner}
        size={100}
      />
    );
  }

  fileProcessSpinner() {
    const { classes } = this.props;
    const { fileName } = this.state;
    return (
      <>
        {fileName && (
          <div className={classes.titleText}>
            File <span className={classes.fileName}>{fileName}</span> is
            processing
          </div>
        )}
        <CircularProgress
          color="primary"
          className={classes.spinner}
          size={150}
        />
      </>
    );
  }

  uploadFileStage() {
    const { classes } = this.props;
    const { isUploading } = this.state;
    return (
      <div>
        <Typography variant="subtitle1" className={classes.header}>
          File Upload
        </Typography>
        <Typography variant="body1" className={classes.subheader}>
          Please upload your HSE file in CSV or Excel format
        </Typography>
        {!isUploading && (
          <FileDropzone
            accept={acceptedMimeTypes}
            onDrop={this.onDropFile}
            className={classes.fileDropZone}
          />
        )}
        {isUploading && this.spinner()}
        <div>
          <Button
            color="primary"
            variant="contained"
            className={classes.uploadButton}
            disabled={isUploading}
            onClick={this.onSelectFile}
          >
            Select file
          </Button>
        </div>
        <div style={{ marginTop: '0.5em' }}>
          <Button
            color="primary"
            variant="outlined"
            className={classes.uploadButton}
            onClick={() => this.setState({ showMapper: true })}
          >
            Show mapper
          </Button>
        </div>
      </div>
    );
  }

  uploadFileStageError() {
    const { classes } = this.props;
    const { isUploading, fileName, errorMessage } = this.state;
    return (
      <div>
        <Typography variant="subtitle1" className={classes.header}>
          File Upload
        </Typography>
        <Typography variant="body1" className={classes.subheader}>
          Please upload your HSE file in CSV or Excel format
        </Typography>
        <img
          src="../images/UploadError.png"
          width="226"
          height="219"
          alt="Upload error"
          className={classes.uploadimg}
        />
        <Typography className={classes.uploadText}>{fileName}</Typography>
        <Typography className={classes.uploadErrorText}>
          {errorMessage}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.uploadButton}
          disabled={!isUploading}
          onClick={this.handleUploadCancel}
        >
          CANCEL
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.uploadButton}
          onClick={this.onSelectFile}
        >
          SELECT FILE
        </Button>
      </div>
    );
  }

  uploadFileResultStage() {
    const { uploadResult } = this.state;
    return uploadResult ? (
      <UploadResultViewer uploadResult={uploadResult} />
    ) : null;
  }

  renderMapper() {
    const { classes } = this.props;
    const {
      mapperItems,
      shouldBeTranslated,
      client,
      reportFile,
      selectedEntityTypes,
    } = this.state;
    return (
      <>
        <EntitiesSelector
          value={selectedEntityTypes}
          onChange={(value) => this.setState({ selectedEntityTypes: value })}
        />
        <UploadMapper
          ref={this.mapperRef}
          initialItems={mapperItems}
          client={client}
          selectedEntityTypes={selectedEntityTypes}
        />
        <div style={{ marginTop: '1em' }}>
          <FormGroup row className={classes.translateSwitch}>
            <FormControlLabel
              control={
                <TranslateSwitch
                  checked={shouldBeTranslated}
                  size="small"
                  onChange={this.handleTranslate}
                />
              }
              label="Translate"
            />
            <Button
              color="primary"
              variant="outlined"
              className={classes.uploadButton}
              onClick={() => this.hideMapperAndSaveItems()}
            >
              Hide mapper
            </Button>
            <Button
              color="primary"
              variant="outlined"
              endIcon={<SendIcon />}
              className={classes.uploadButton}
              disabled={!client || !reportFile}
              onClick={() => this.fileProcessMappedItems()}
            >
              Process mapped items
            </Button>
          </FormGroup>
        </div>
      </>
    );
  }

  renderFileSelector() {
    const { isUploadingError, isReportChecked } = this.state;
    return (
      <Paper elevation={0} square={true}>
        {!isUploadingError && !isReportChecked && this.uploadFileStage()}
        {isUploadingError && !isReportChecked && this.uploadFileStageError()}
        {isReportChecked && this.uploadFileResultStage()}
      </Paper>
    );
  }

  hideMapperAndSaveItems = () => {
    this.setState({
      showMapper: false,
      mapperItems: this.mapperRef.current.getItems(),
    });
  };

  render() {
    const { classes } = this.props;
    const {
      isReportChecked,
      showMapper,
      client,
      defaultClient,
      isUploading,
      errorMessage,
      isMappingErrorVisible,
    } = this.state;

    return (
      <>
        <div className={classes.table} style={{ margin: '1em auto' }}>
          <div className={classes.row}>
            <div className={classes.cellCenter}>
              <ClientComboBox
                value={client}
                defaultValue={defaultClient}
                onChange={(event, value) => this.setState({ client: value })}
                style={{ maxWidth: 300 }}
                excludeSuperOrganisation
              />
            </div>
          </div>
        </div>
        <div className={classes.table}>
          <div className={classes.row}>
            <div className={classes.cellCenter}>
              <Paper className={classes.paper}>
                {isUploading
                  ? this.fileProcessSpinner()
                  : showMapper
                  ? this.renderMapper()
                  : this.renderFileSelector()}
              </Paper>
            </div>
          </div>
        </div>

        <div className={classes.table} style={{ marginTop: '1em' }}>
          <div className={classes.row}>
            <div className={classes.cellLeft}>
              <Button
                color="primary"
                variant="outlined"
                onClick={this.handleUploadAnother}
                disabled={!isReportChecked}
              >
                <KeyboardBackspaceIcon className={classes.buttonIcon} />
                UPLOAD ANOTHER FILE
              </Button>
            </div>
          </div>
        </div>
        <InfoDialog
          open={isMappingErrorVisible}
          onClose={() => this.setState({ isMappingErrorVisible: false })}
          text={errorMessage}
        />
      </>
    );
  }
}

ReportUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReportUpload);
