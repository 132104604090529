import React from 'react';
import { ENTITY_NAMES, ENTITY_TYPES } from './upload-mapper/constants';
import CheckBoxGroup from '../common/CheckBoxGroup';
import PropTypes from 'prop-types';
import { Card, CardHeader, withStyles } from '@material-ui/core';

const styles = {
  container: {
    marginBottom: 10,
    paddingBottom: 10,
  },
  content: {
    justifyContent: 'center',
  },
};

const ENTITIES = [
  { type: ENTITY_TYPES.Reports, name: ENTITY_NAMES.Reports },
  { type: ENTITY_TYPES.Locations, name: ENTITY_NAMES.Locations },
  { type: ENTITY_TYPES.Facilities, name: ENTITY_NAMES.Facilities },
  {
    type: ENTITY_TYPES.AssociatedReports,
    name: ENTITY_NAMES.AssociatedReports,
  },
  { type: ENTITY_TYPES.Organisations, name: ENTITY_NAMES.Organisations },
];

function EntitiesSelector({ value, onChange, classes }) {
  return (
    <Card variant="outlined" className={classes.container}>
      <CardHeader title="Select entities to create or update" />
      <CheckBoxGroup
        value={value}
        onChange={onChange}
        options={ENTITIES}
        nameSelector={(option) => option.name}
        valueSelector={(option) => option.type}
        className={classes.content}
      />
    </Card>
  );
}

EntitiesSelector.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EntitiesSelector);
