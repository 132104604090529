import { Badge, Tooltip } from '@material-ui/core';
import React, { CSSProperties, FC } from 'react';
import { AlertNotificationDto } from '../../api/apiDtos';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';

const badgeStyles: CSSProperties = {
  margin: '0 1em',
  cursor: 'pointer',
};

interface Props {
  onClick?: (event: React.MouseEvent<any>) => void;
  alertNotifications: AlertNotificationDto[];
}

export const NotificationBell: FC<Props> = ({
  onClick,
  alertNotifications,
}) => {
  const unread = alertNotifications.filter((x) => !x.isRead);

  const noNotifications = unread.length === 0;

  return (
    <div onClick={onClick}>
      <Badge
        overlap="rectangular"
        style={badgeStyles}
        badgeContent={unread.length}
        color="secondary"
        invisible={noNotifications}
      >
        <Tooltip title="Alert Notifications">
          <NotificationsIcon style={{ color: '#7B7B7B' }} />
        </Tooltip>
      </Badge>
    </div>
  );
};
