// @ts-nocheck
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ClientComboBox from '../combobox/client-combo-box';
import API from '../../services/ApiInstance';
import apiRoutes from '../../services/ApiRoutes';
import ValidationService from '../../services/authentication/ValidationService';
import InfoDialog from '../dialog/InfoDialog';
import { toast } from 'react-toastify';

const styles = {
  buttonWrapper: {
    display: 'inline-block',
    marginRight: '1em',
  },
  rightIcon: {
    marginLeft: '1em',
  },
  select: {
    margin: '0 1em',
    minWidth: '10em',
  },
  card: {
    maxWidth: 400,
  },
};

const VALIDATION_ERROR_TEXT =
  'The form could not be submitted - please fill all the required fields.';

class UserPesonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: '',
      primOrg: null,
      originalEmail: '',
      originalPhone: '',
      email: '',
      username: '',
      phone: '',
      errorDialog: false,
    };
    this.validator = new ValidationService();
  }

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser() {
    API.post(apiRoutes.userPersonalDetails).then((response) => {
      const { phone, email, username, roleName, primaryOrganisation } =
        response;
      this.setState({
        username,
        role: roleName,
        email: email ? email : '',
        originalEmail: email ? email : '',
        phone: phone,
        originalPhone: phone,
        primOrg: primaryOrganisation,
      });
    });
  }

  updateUser() {
    const updateUser = {
      username: this.state.username,
      email: this.state.email,
      phone: this.state.phone,
      primaryOrganisation: this.state.primOrg,
    };

    const isValid = this.validator.validate(
      ValidationService.validators.required(updateUser.email),
      ValidationService.validators.email(updateUser.email),
      ValidationService.validators.required(updateUser.primaryOrganisation)
    );

    if (isValid) {
      API.post(apiRoutes.updateUserSelf, updateUser).then(() => {
        this.handleClose();
        this.setState({
          originalEmail: updateUser.email,
          originalPhone: updateUser.phone,
          email: updateUser.email,
          phone: updateUser.phone,
        });
        toast.success('Updated successfully');
      });
    } else {
      this.setState({ errorDialog: true });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (prop) => (e) => {
    const newState = {};

    if (e.target) {
      newState[prop] = e.target.value;
    }
    this.setState(newState);
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ padding: '1em' }}>
        <Card variant="outlined" className={classes.card}>
          <CardContent>
            <Typography variant="h5" className={classes.header}>
              Personal Details
            </Typography>

            <Typography variant="subtitle1">
              Name: {this.state.username}
            </Typography>
            <Typography variant="subtitle1">Role: {this.state.role}</Typography>

            <ClientComboBox
              value={this.state.primOrg}
              onChange={(e, val) => this.setState({ primOrg: val })}
              customLabel="Primary organisation *"
            />
            <TextField
              required
              error={
                !!this.state.email &&
                !this.validator.validate(
                  ValidationService.validators.email(this.state.email)
                )
              }
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              value={this.state.email}
              fullWidth
              onChange={this.handleChange('email')}
            />

            <CardActions>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => this.updateUser()}
              >
                Submit
              </Button>
            </CardActions>
          </CardContent>
        </Card>
        {this.state.errorDialog && (
          <InfoDialog
            open={this.state.errorDialog}
            onClose={() => this.setState({ errorDialog: false })}
            text={VALIDATION_ERROR_TEXT}
          />
        )}
      </div>
    );
  }
}

UserPesonalDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserPesonalDetails);
