// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
} from '@material-ui/core';
import NewOrganisationDialog from './NewOrganisationDialog';
import EditOrganisationDialog from './EditOrganisationDialog';
import ApiRoutes from '../../services/ApiRoutes';
import API from '../../services/ApiInstance';
import UserPreferenceService from '../../services/UserPreferenceService';
import { toast } from 'react-toastify';
import Spacer from '../common/Spacer';
import { ADDITIONAL_TOPICS } from '../../../src/constants';
import { CustomLabelComponent } from './CustomLabelComponent';

const userPreferenceService = new UserPreferenceService();

const styles = {
  header: {
    display: 'flex',
  },
};

const chipCompStyles = {
  display: 'flex',
  alignContent: 'center',
  gap: '1em',
};

const sortByLevel = (a, b) => a.level - b.level;

function OrganisationManagement({ classes }) {
  const [organisations, setOrganisations] = useState([]);
  const [industrySectors, setIndustrySectors] = useState([]);
  const [additionalTopics, setAdditionalTopics] = useState([]);
  const [locations, setLocations] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  async function fetchOrganisations() {
    await API.get(ApiRoutes.orgsForAdminView).then((res) => {
      setOrganisations(res.sort(sortByLevel));
    });
  }

  const fetchIndustrySectors = () => {
    API.get(ApiRoutes.industrySectors).then((res) => setIndustrySectors(res));
  };

  const fetchLocations = () => {
    API.get(ApiRoutes.globalLocations).then((res) => setLocations(res));
  };

  useEffect(() => {
    fetchOrganisations();
    fetchIndustrySectors();
    fetchLocations();
  }, []);

  useEffect(() => {
    const savedRows = userPreferenceService.get('OrgManagementRows');
    if (savedRows !== null) {
      setRowsPerPage(savedRows);
    }
    const savedPage = userPreferenceService.get('OrgManagementPage');
    if (savedPage !== null) {
      setPage(savedPage);
    }
  }, []);
  useEffect(() => {
    userPreferenceService.save('OrgManagementRows', rowsPerPage);
  }, [rowsPerPage]);
  useEffect(() => {
    userPreferenceService.save('OrgManagementPage', page);
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onOrganisationAdded = () => {
    fetchOrganisations();
    toast.success('Organisation added successfully');
  };

  async function onOrganisationEdited() {
    await fetchOrganisations();
    toast.success('Organisation updated successfully');
  }

  const RenderCustomLabel = ({ topicName, customLabel }) => {
    return (
      <div styles={chipCompStyles}>
        {topicName + (customLabel !== '' ? ' :' : '')}
        {customLabel !== '' ? (
          <Chip
            style={{ marginLeft: '0.5em' }}
            size="small"
            label={customLabel}
            color="primary"
          />
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={classes.header}>
        <Spacer />
        <NewOrganisationDialog
          industrySectors={industrySectors}
          locations={locations}
          onSuccess={onOrganisationAdded}
        />
      </div>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Parent</TableCell>
              <TableCell>Is Active</TableCell>
              <TableCell>Industry sector</TableCell>
              <TableCell>Additional Topics</TableCell>
              <TableCell>Location</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organisations
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((org) => {
                return (
                  <TableRow key={org.id}>
                    <TableCell>{org.name}</TableCell>
                    <TableCell>{org.parent ? org.parent.name : ''}</TableCell>
                    <TableCell>{org.isActive ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      {org.industrySector ? org.industrySector.name : ''}
                    </TableCell>
                    <TableCell>
                      {org.additionalTopics
                        ? org.additionalTopics.map((addTopic) => {
                            return (
                              <Chip
                                styles={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                                key={addTopic.additionalTopicName}
                                label={
                                  <RenderCustomLabel
                                    topicName={addTopic.additionalTopicName}
                                    customLabel={addTopic.customLabel}
                                  />
                                }
                              />
                            );
                          })
                        : ''}
                    </TableCell>
                    <TableCell>
                      {org.location ? org.location.name : ''}
                    </TableCell>
                    <TableCell>
                      <EditOrganisationDialog
                        organisation={org}
                        industrySectors={industrySectors}
                        locations={locations}
                        onSuccess={onOrganisationEdited}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={organisations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        ></TablePagination>
      </Paper>
    </>
  );
}

export default withStyles(styles)(OrganisationManagement);
