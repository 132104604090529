// @ts-nocheck
import React, { useState } from 'react';
import { Button, Grid, TextField, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { dateFormats } from '../../../helpers/date-helper';

const styles = {
  formDescription: {
    marginTop: 8,
  },
  addButton: {
    float: 'right',
    height: '100%',
  },
};

const NewActionForm = ({ onSubmit, classes }) => {
  const [date, setDate] = useState(moment());
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const submitNewAction = () => {
    const newAction = {
      date,
      dateText: moment(date).format(dateFormats.common),
      title,
      description,
    };
    onSubmit(newAction);
    setTitle('');
    setDescription('');
  };

  return (
    <Grid container direction="column">
      <Grid item container direction="row">
        <Grid item xs={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              inputVariant="outlined"
              variant="dialog"
              label="Date"
              value={date}
              format={dateFormats.compact}
              onChange={(d) => setDate(d)}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Title *"
            color="primary"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            className={classes.addButton}
            color="primary"
            variant="outlined"
            onClick={submitNewAction}
            disabled={!date || !title || !description}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.formDescription}
          multiline
          rows={2}
          fullWidth
          variant="outlined"
          label="Description *"
          color="primary"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

NewActionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewActionForm);
