const noDataLabel = 'No data for this time range';

export type ReportsByDatesDefaultSettingsType =
  typeof ReportsByDatesDefaultSettings;
export type ReportAveragesByClassificationDefaultSettingsType =
  typeof ReportAveragesByClassificationDefaultSettings;
export type ReportHistoryDefaultSettingsType =
  typeof ReportHistoryDefaultSettings;
export type ReportTableDefaultSettingsType = typeof ReportTableDefaultSettings;
export type AssociatedReportTableDefaultSettingsType =
  typeof AssociatedReportTableDefaultSettings;
export type TopicTrendsDefaultSettingsType = typeof TopicTrendsDefaultSettings;
export type ReportsByOrganisationsDefaultSettingsType =
  typeof ReportsByOrganisationsDefaultSettings;
export type ReportsByTypesDefaultSettingsType =
  typeof ReportsByTypesDefaultSettings;
export type AssociatedReportsCostDefaultSettingsType =
  typeof AssociatedReportsCostDefaultSettings;
export type MapWidgetDefaultSettingsType = typeof MapWidgetDefaultSettings;
export type RootCauseBreakdownsDefaultSettingsType =
  typeof RootCauseBreakdownsDefaultSettings;
export type PriorityRootCauseClustersDefaultSettingsType =
  typeof PriorityRootCauseClustersDefaultSettings;
export type PreventiveActionsGuidanceDefaultSettingsType =
  typeof PreventiveActionsGuidanceDefaultSettings;
export type RootCauseToTopicCorrelationsDefaultSettingsType =
  typeof RootCauseToTopicCorrelationsDefaultSettings;
export type PriorityHazardClustersDefaultSettingsType =
  typeof PriorityHazardClustersDefaultSettings;
export type LifeSavingRulesDefaultSettingsType =
  typeof LifeSavingRulesDefaultSettings;

export type WidgetSettings =
  | ReportsByDatesDefaultSettingsType
  | ReportAveragesByClassificationDefaultSettingsType
  | ReportHistoryDefaultSettingsType
  | ReportTableDefaultSettingsType
  | AssociatedReportTableDefaultSettingsType
  | TopicTrendsDefaultSettingsType
  | ReportsByOrganisationsDefaultSettingsType
  | ReportsByTypesDefaultSettingsType
  | AssociatedReportsCostDefaultSettingsType
  | MapWidgetDefaultSettingsType
  | RootCauseBreakdownsDefaultSettingsType
  | PriorityRootCauseClustersDefaultSettingsType
  | PreventiveActionsGuidanceDefaultSettingsType
  | RootCauseToTopicCorrelationsDefaultSettingsType
  | PriorityHazardClustersDefaultSettingsType
  | LifeSavingRulesDefaultSettingsType;

export const ReportsByDatesDefaultSettings = {
  title: '',
  axisY: 'TOTAL REPORTED',
  info: 'info about Report Dates By Type widget',
  noDataLabel: noDataLabel,
  typesLabel: 'Totals',
  organisationsLabel: 'Partners',
  reportTypeAggregationThreshold: '5',
  organisationAggregationThreshold: '5',
};

export const ReportAveragesByClassificationDefaultSettings = {
  title: 'Report Averages By Classification',
  axisX: 'Reports per Facilities per Month',
  axisY: 'Classification data',
  info: 'info about Report Averages By Classification widget',
  noDataLabel: noDataLabel,
  typesLabel: 'Categories',
  organisationsLabel: 'Organisations',
  percentageItemsPostfix: 'reports',
  fractionalItemsPostfix: 'per facilities / months',
};

export const ReportHistoryDefaultSettings = {
  title: 'Report History',
  info: 'Additional information about the Report History widget',
  totalItemsLabel: 'Total Reports',
  reportTypeAggregationThreshold: '5',
};

export const ReportTableDefaultSettings = {
  title: 'Report Table',
  info: 'Additional information about the Report Table widget',
  noDataLabel: noDataLabel,
};

export const AssociatedReportTableDefaultSettings = {
  title: 'Associated Report Table',
  info: 'Additional information about the Associated Report Table widget',
  noDataLabel: noDataLabel,
};

export const TopicTrendsDefaultSettings = {
  title: 'Latest Trends',
  info: 'Additional information about Latest Topics Trends widget',
  topic: 'Topic',
  reportsPercent: 'Report %',
  previousPeriodReportsPercent: 'Previous Period',
  currentPeriodReportsPercent: 'Current Period',
  noDataLabel: noDataLabel,
  defaultTopicsSource: 'Default Topics Source',
};

export const ReportsByOrganisationsDefaultSettings = {
  title: 'Reports by organisation',
  axisX: 'organisation',
  axisY: 'Reports by organisation',
  info: 'Additional information about the Reports by Organisations widget',
};

export const ReportsByTypesDefaultSettings = {
  title: 'Reports by type',
  axisY: 'Reports by type',
  info: 'Additional information about the Reports by Types widget',
  itemsLabel: 'incidents',
};

export const AssociatedReportsCostDefaultSettings = {
  title: 'Claim Costs Over Time',
  currency: '£',
  totalItemsLabel: 'No. claims',
  axisY: 'GBP COST',
  info: 'Additional information about the Associated Reports Costs widget',
};

export const MapWidgetDefaultSettings = {
  title: 'Reports Locations',
  typesLabel: 'Report',
  reportedTooltip: 'Reported',
  info: 'Additional information about the Reports Locations widget',
};

export const RootCauseBreakdownsDefaultSettings = {
  title: 'Root Cause Breakdowns',
  axisY: 'ROOT CAUSE DISTRIBUTION',
  info: 'Additional information about Root Cause Breakdowns widget',
  noDataLabel: noDataLabel,
  organisationsLabel: 'Organisation',
  typesLabel: 'Root Cause',
};

export const PriorityRootCauseClustersDefaultSettings = {
  title: 'Priority Root Cause clusters',
  info: 'Additional information about the Priority Root Cause clusters widget',
  rootCauseType: 'Root Cause Type',
  reportsPercent: 'Report %',
  previousPeriodReportsPercent: 'Previous Period',
  currentPeriodReportsPercent: 'Current Period',
  noDataLabel: noDataLabel,
};

export const PreventiveActionsGuidanceDefaultSettings = {
  title: 'Preventive Actions Guidance',
  subtitle:
    'Recommended preventive actions to reduce risk from priority Root Causes',
  info: 'Additional information about Preventive Actions Guidance widget',
  noDataLabel: noDataLabel,
  rootCauseType: 'Root Cause Type',
  shortTermRecommendedActivity: 'Short Term Recommended Activity',
  longTermRecommendedActivity: 'Long Term Recommended Activity',
  objectiveLabel: 'Objective',
};

export const RootCauseToTopicCorrelationsDefaultSettings = {
  title: 'Root Cause & Topic Correlations',
  info: 'Additional information about the Root Cause to Topic correlations widget',
  rootCauseType: 'Root Cause',
  topic: 'Root Cause Topics',
  noDataLabel: noDataLabel,
};

export const PriorityHazardClustersDefaultSettings = {
  title: 'Priority Hazards',
  info: 'Additional information about the Priority Hazards widget',
  hazard: 'Hazard Type',
  reportsPercent: 'Report %',
  previousPeriodReportsPercent: 'Previous Period',
  currentPeriodReportsPercent: 'Current Period',
  noDataLabel: noDataLabel,
};

export const LifeSavingRulesDefaultSettings = {
  title: 'Life Saving Rules',
  info: 'Additional information about the Life Saving Rules widget',
  axisY: 'Total reported',
  noDataLabel: noDataLabel,
};

export const ReportsWordcloudDefaultSettings = {
  title: 'Wordcloud',
  info: 'Prominent keywords that appeared across all specified reports',
};
