// @ts-nocheck
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const styles = {
  closeButton: {
    position: 'absolute',
    right: 0,
    margin: '3px 5px',
  },
};

const titles = {
  general: 'General Info',
};

class HelpDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {
        general: this.GeneralContent(),
      },
    };
  }

  GeneralContent() {
    return (
      <>
        <Typography variant="subtitle1">
          How do I use this application?
        </Typography>
        <Typography align="justify" paragraph={true} variant="body2">
          Use this application by clicking on buttons and looking at the screen.
        </Typography>
      </>
    );
  }

  render() {
    const { open, onClose, type, classes } = this.props;
    const { content } = this.state;
    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <DialogTitle>
          {titles[type] === undefined ? '' : titles[type]}
        </DialogTitle>
        <DialogContent>{content[type]}</DialogContent>
      </Dialog>
    );
  }
}
HelpDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default withStyles(styles)(HelpDialog);
