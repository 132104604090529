import React, { useState, useEffect, FC } from 'react';
import api from '../services/ApiInstance';
import ApiRoutes from '../services/ApiRoutes';
import { makeStyles, Typography } from '@material-ui/core';

const styles = makeStyles({
  container: {
    padding: '1em',
  },
  header: {
    marginBottom: '1em',
  },
  content: {
    textAlign: 'center',
  },
});

const About: FC = () => {
  const classes = styles();

  const [version, setVersion] = useState<string>('');

  useEffect(() => {
    const getAppVersion = async () => {
      const response = await api.get<any, string>(ApiRoutes.version);
      setVersion(response);
    };
    getAppVersion();
  }, []);

  return (
    <div className={classes.container}>
      <Typography variant="h4" align="center" className={classes.header}>
        About Safety Scanner
      </Typography>
      <div className={classes.content}>
        <p>{`Version: ${version}`}</p>
      </div>
    </div>
  );
};

export default About;
