// @ts-nocheck
import React, { Component } from 'react';
import { Typography, Grid, withStyles } from '@material-ui/core';
import GeoPyLicence from './GeoPy';

const styles = (theme) => ({
  wrapper: {
    padding: '0 20px 0 20px',
  },
});

class Licences extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Third Party Licence Details</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6">GeoPy</Typography>
            <GeoPyLicence />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Licences);
