import React from 'react';
import { Grid, Switch, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

export const CHART_FORMATS = {
  TotalCounts: 'Total Counts',
  Percentages: 'Percentages',
};

const styles = {
  root: {
    display: 'flex',
    padding: '0 10px',
  },
  formatSwitcher: {
    '& .MuiSwitch-track': {
      backgroundColor: '#d94936 !important',
    },
    '& .MuiSwitch-thumb': {
      color: '#d94936',
    },
  },
};

const ChartFormatToggle = ({ value, onChange, classes }) => {
  const toggleFormat = () => {
    const newFormat =
      value === CHART_FORMATS.TotalCounts
        ? CHART_FORMATS.Percentages
        : CHART_FORMATS.TotalCounts;
    onChange(newFormat);
  };

  const checked = value === CHART_FORMATS.Percentages;

  return (
    <Paper variant="outlined" className={classes.root}>
      <Grid container alignItems="center">
        <Grid item>Total counts</Grid>
        <Grid item>
          <Switch
            className={classes.formatSwitcher}
            checked={checked}
            onChange={toggleFormat}
            color="primary"
          />
        </Grid>
        <Grid item>Percentages</Grid>
      </Grid>
    </Paper>
  );
};

ChartFormatToggle.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChartFormatToggle);
