import {
  Accordion,
  AccordionSummary,
  Grid,
  Paper,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import api from '../../services/ApiInstance';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { FC, useEffect } from 'react';
import { Theme } from 'react-toastify';
import { AlertIcon } from '../notification-panel/AlertIcon';
import { alertNotifications } from '../notification-panel/NotificationPanel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@mui/material/Alert';
import ApiRoutes from '../../services/ApiRoutes';
import { signal } from '@preact/signals-react';
import { useHistory } from 'react-router-dom';
import inactiveAlertWarning from './WarningAlertGrey.svg';
import { AlertDto, AlertNotificationDto, TrackerDto } from '../../api/apiDtos';
import API from '../../services/ApiInstance';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {},
    paper: {
      height: '100%',
      width: '100%',
    },
    accordionBody: {
      background: '#E2E2E2',
      borderRadius: '0 0 4px 4px',
      borderTop: '1px solid #D9D9D9',
      minHeight: '160px',
      paddingTop: '20px',
    },
    table: {
      minWidth: 'calc(100% - 20px)',
    },
    unreadRow: {
      backgroundColor: '#ffe3e3',
    },
    headerRow: {
      backgroundColor: '#D3D3D3',
      pointerEvents: 'none',
    },
    rowStyling: {
      height: '22px',
    },
    rowAlertIconStyling: {
      padding: '0px',
      '& .MuiAlert-icon': {
        padding: '0px',
        babackgroundColor: 'transparent',
      },
    },
  })
);

interface Props {}
export const listOfTrackers = signal<TrackerDto[]>([]);
export const listOfAlerts = signal<AlertDto[]>([]);
export const NotificationTable: FC<Props> = ({}) => {
  function pushUpdatedNotification(notification: AlertNotificationDto) {
    alertNotifications.value = [
      ...alertNotifications
        .peek()
        .map((x) => (x.id === notification.id ? notification : x)),
    ];
  }

  useEffect(() => {
    api
      .get<unknown, TrackerDto[]>(ApiRoutes.getTrackers)
      .then((_listOfTrackers) => {
        listOfTrackers.value = _listOfTrackers;
      });
    api.get<unknown, AlertDto[]>(ApiRoutes.getAlerts).then((_listOfAlerts) => {
      listOfAlerts.value = _listOfAlerts;
    });
  }, []);

  const history = useHistory();

  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{ height: '55px' }}
        >
          <AlertIcon
            type={
              alertNotifications.value?.filter((x) => !x.isRead).length
                ? 'unread'
                : 'read'
            }
          />
          <Typography style={{ padding: '10px', fontWeight: 550 }}>
            {alertNotifications.value?.filter((x) => !x.isRead).length} Alerts
            Triggered
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Grid container spacing={4}>
            <Grid item xs={6} md={6}>
              <div style={{ fontWeight: 550, paddingBottom: '10px' }}>
                {' '}
                Trackers{' '}
              </div>
              <TableContainer
                component={Paper}
                style={{
                  overflowY:
                    listOfTrackers.value.length > 4 ? 'auto' : 'hidden',
                  maxHeight: listOfTrackers.value.length > 4 ? '212px' : 'auto',
                }}
              >
                <Table className={classes.table} size="small">
                  <TableHead className={classes.table}>
                    <TableRow className={classes.headerRow}>
                      <TableCell>Name</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Last Updated</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listOfTrackers?.value.map((row) => (
                      <TableRow
                        key={row.id}
                        onClick={() => history.push(`/trackers/${row.id}`)}
                      >
                        <TableCell className={classes.rowStyling}>
                          {row?.name}
                        </TableCell>
                        <TableCell>{row?.dateCreated?.split('T')[0]}</TableCell>
                        <TableCell>{row.dateUpdated?.split('T')[0]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={6} md={6}>
              <div style={{ fontWeight: 550, paddingBottom: '10px' }}>
                {' '}
                Alerts{' '}
              </div>
              <TableContainer
                component={Paper}
                style={{
                  overflowY:
                    alertNotifications.value.length > 4 ? 'auto' : 'hidden',
                  maxHeight:
                    alertNotifications.value.length > 4 ? '212px' : 'auto',
                }}
              >
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow className={classes.headerRow}>
                      <TableCell style={{ width: '1px' }}></TableCell>
                      <TableCell> Name</TableCell>
                      <TableCell>Date Triggered</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {alertNotifications.value.map((row) => (
                      <TableRow
                        key={row.alertId}
                        className={!row.isRead ? classes.unreadRow : ''}
                        onClick={() => {
                          API.put<any, AlertNotificationDto>(
                            `${ApiRoutes.markAlertNotificationRead}/${row.id}`,
                            row.id
                          )
                            .then((res) => pushUpdatedNotification(res))
                            .catch((err) => console.error(err));
                          history.push(`/trackers/${row.alertId}`);
                        }}
                      >
                        <TableCell className={classes.rowAlertIconStyling}>
                          <Alert
                            severity="warning"
                            color="error"
                            sx={{ bgcolor: 'transparent' }}
                          ></Alert>
                        </TableCell>
                        <TableCell>{row.alert.name}</TableCell>
                        <TableCell>{row.createdDate.split('T')[0]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};
