import {
  ENTITY_TYPES,
  UNMAPPED_ITEMS_POOL,
  EXTRA_ITEMS_POOL,
} from './constants';

export const MapperCards = [
  {
    title: 'Report ID',
    name: 'ReportId',
    requiredFor: [ENTITY_TYPES.Reports, ENTITY_TYPES.AssociatedReports],
    categories: [ENTITY_TYPES.Reports, ENTITY_TYPES.AssociatedReports],
    itemised: false,
  },
  {
    title: 'Facility ID',
    name: 'FacilityId',
    requiredFor: [
      ENTITY_TYPES.Reports,
      ENTITY_TYPES.Locations,
      ENTITY_TYPES.Facilities,
    ],
    categories: [
      ENTITY_TYPES.Reports,
      ENTITY_TYPES.Locations,
      ENTITY_TYPES.Facilities,
    ],
    itemised: false,
  },
  {
    title: 'Date occurred',
    name: 'DateOccurred',
    requiredFor: [ENTITY_TYPES.Reports],
    categories: [ENTITY_TYPES.Reports],
    itemised: false,
  },
  {
    title: 'Report type',
    name: 'ReportType',
    requiredFor: [],
    categories: [ENTITY_TYPES.Reports],
    itemised: true,
  },
  {
    title: 'Report description',
    name: 'Description',
    requiredFor: [ENTITY_TYPES.Reports],
    categories: [ENTITY_TYPES.Reports],
    itemised: false,
  },
  {
    title: 'Date reported',
    name: 'DateReported',
    requiredFor: [],
    categories: [ENTITY_TYPES.Reports],
    itemised: false,
  },
  {
    title: 'Affected party',
    name: 'AffectedParty',
    requiredFor: [],
    categories: [ENTITY_TYPES.Reports],
    itemised: true,
  },
  {
    title: 'Area',
    name: 'Area',
    requiredFor: [],
    categories: [ENTITY_TYPES.Reports],
    itemised: true,
  },
  {
    title: 'Event type',
    name: 'EventType',
    requiredFor: [],
    categories: [ENTITY_TYPES.Reports],
    itemised: true,
  },
  {
    title: 'Facility name',
    name: 'Facility',
    requiredFor: [ENTITY_TYPES.Facilities],
    categories: [ENTITY_TYPES.Facilities],
    itemised: true,
  },
  {
    title: 'Organisation',
    name: 'Organisation',
    requiredFor: [ENTITY_TYPES.Facilities, ENTITY_TYPES.Organisations],
    categories: [ENTITY_TYPES.Facilities, ENTITY_TYPES.Organisations],
    itemised: true,
  },
  {
    title: 'Organisation parent',
    name: 'OrganisationParent',
    requiredFor: [ENTITY_TYPES.Organisations],
    categories: [ENTITY_TYPES.Organisations],
    itemised: true,
  },
  {
    title: 'Postcode',
    name: 'Postcode',
    requiredFor: [],
    categories: [ENTITY_TYPES.Facilities],
    itemised: false,
  },
  {
    title: 'Longitude',
    name: 'Longitude',
    requiredFor: [ENTITY_TYPES.Locations],
    categories: [ENTITY_TYPES.Locations],
    itemised: false,
  },
  {
    title: 'Latitude',
    name: 'Latitude',
    requiredFor: [ENTITY_TYPES.Locations],
    categories: [ENTITY_TYPES.Locations],
    itemised: false,
  },
  {
    title: 'Location date',
    name: 'LocationDate',
    requiredFor: [ENTITY_TYPES.Locations],
    categories: [ENTITY_TYPES.Locations],
    itemised: false,
  },
  {
    title: 'Report latitude',
    name: 'ReportLatitude',
    requiredFor: [],
    categories: [ENTITY_TYPES.Reports],
    itemised: false,
  },
  {
    title: 'Report longitude',
    name: 'ReportLongitude',
    requiredFor: [],
    categories: [ENTITY_TYPES.Reports],
    itemised: false,
  },
  {
    title: 'Additional field 1',
    name: 'AdditionalField1',
    requiredFor: [],
    categories: [ENTITY_TYPES.Reports],
    itemised: false,
  },
  {
    title: 'Additional field 2',
    name: 'AdditionalField2',
    requiredFor: [],
    categories: [ENTITY_TYPES.Reports],
    itemised: false,
  },
  {
    title: 'Additional field 3',
    name: 'AdditionalField3',
    requiredFor: [],
    categories: [ENTITY_TYPES.Reports],
    itemised: false,
  },
  {
    title: 'Additional field 4',
    name: 'AdditionalField4',
    requiredFor: [],
    categories: [ENTITY_TYPES.Reports],
    itemised: false,
  },
  {
    title: 'Associated report ID',
    name: 'AssociatedReportId',
    requiredFor: [ENTITY_TYPES.AssociatedReports],
    categories: [ENTITY_TYPES.AssociatedReports],
    itemised: false,
  },
  {
    title: 'Associated report title',
    name: 'AssociatedReportTitle',
    requiredFor: [ENTITY_TYPES.AssociatedReports],
    categories: [ENTITY_TYPES.AssociatedReports],
    itemised: false,
  },
  {
    title: 'Associated report description',
    name: 'AssociatedReportDescription',
    requiredFor: [ENTITY_TYPES.AssociatedReports],
    categories: [ENTITY_TYPES.AssociatedReports],
    itemised: false,
  },
  {
    title: 'Value 1',
    name: 'Value1',
    requiredFor: [ENTITY_TYPES.AssociatedReports],
    categories: [ENTITY_TYPES.AssociatedReports],
    itemised: false,
  },
  {
    title: 'Value 2',
    name: 'Value2',
    requiredFor: [ENTITY_TYPES.AssociatedReports],
    categories: [ENTITY_TYPES.AssociatedReports],
    itemised: false,
  },
  {
    title: 'Date of incident',
    name: 'AssociatedReportDate',
    requiredFor: [ENTITY_TYPES.AssociatedReports],
    categories: [ENTITY_TYPES.AssociatedReports],
    itemised: false,
  },
  {
    title: 'Value 1 Unit',
    name: 'Value1Unit',
    requiredFor: [],
    categories: [ENTITY_TYPES.AssociatedReports],
    itemised: false,
  },
  {
    title: 'Value 2 Unit',
    name: 'Value2Unit',
    requiredFor: [],
    categories: [ENTITY_TYPES.AssociatedReports],
    itemised: false,
  },
];

export function IsCardNameValid(name) {
  return (
    MapperCards.map((i) => i.name).includes(name) ||
    name === UNMAPPED_ITEMS_POOL ||
    name === EXTRA_ITEMS_POOL
  );
}
