import React, { FC, useEffect, useState } from 'react';
import {
  DialogTitle,
  Theme,
  WithStyles,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import AlertsForm from './AlertsForm';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '20px',
  },
}));

interface Props {
  Type: string;
}

const AlertsView: FC<Props> = ({ Type }) => {
  const classes = useStyles();

  return (
    <>
      <DialogTitle>{Type} Alert</DialogTitle>
      <div className={classes.container}>
        <AlertsForm Type={Type} />
      </div>
    </>
  );
};

export default AlertsView;
