import React, { useState } from 'react';
import { withStyles, TextField } from '@material-ui/core';
import LocalComboBox from '../combobox/local-combo-box';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import AddEntityDialog from '../dialog/AddEntityDialog';

const styles = {
  formControl: {
    marginBottom: 10,
  },
};

const AddFacilityDialog = ({ organisations, onAdd, classes }) => {
  const [local_Id, setLocal_Id] = useState('');
  const [name, setName] = useState('');
  const [postcode, setPostcode] = useState('');
  const [organisation, setOrganisation] = useState(null);

  const resetForm = () => {
    setLocal_Id('');
    setName('');
    setPostcode('');
    setOrganisation(null);
  };

  const validate = () => ({
    valid: local_Id && name && organisation,
  });

  const addFacility = async () => {
    const newFacility = {
      localId: local_Id,
      name,
      postcode,
      organisation,
    };
    await api.post(ApiRoutes.createFacility, newFacility);
    toast.success('Facility added');
    onAdd();
  };

  return (
    <AddEntityDialog
      buttonName="Add Facility"
      title="Add Facility"
      validateFunc={validate}
      addFunc={addFacility}
      onClose={() => resetForm()}
    >
      <TextField
        value={name}
        required
        variant="outlined"
        label="Name"
        fullWidth
        onChange={(e) => { setName(e.target.value); setLocal_Id(e.target.value); }}
        className={classes.formControl}
      />
      <TextField
        value={postcode}
        variant="outlined"
        label="Postcode"
        fullWidth
        onChange={(e) => setPostcode(e.target.value)}
        className={classes.formControl}
      />
      <LocalComboBox
        options={organisations}
        value={organisation}
        getOptionLabel={(option) => option.name}
        onChange={(e, value) => setOrganisation(value)}
        customLabel="Organisation *"
      />
    </AddEntityDialog>
  );
};

AddFacilityDialog.propTypes = {
  organisations: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddFacilityDialog);
