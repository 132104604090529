import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

function InfoDialog({ open, onClose, text }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <p>{text}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InfoDialog;
