import React, { useState } from 'react';
import { withStyles, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import ChartFormatToggle, { CHART_FORMATS } from './ChartFormatToggle';
import ChartDataVisualisationTypeToggle from './ChartDataVisualisationTypeToggle';
import ChartDataPeriodSelector from './ChartDataPeriodSelector';
import Spacer from '../common/Spacer';
import TrackerChart from '../charts/TrackerChart';
import TrackerActionsDialog from './actions/TrackerActionsDialog';
import { dateFormats } from '../../helpers/date-helper';
import moment from 'moment';
import { ActionDto } from '../../api/apiDtos';
import { createStyles, WithStyles } from '@material-ui/styles';
import { selectedAlert } from '../../signals/selectedAlert';

const styles = createStyles({
  header: {
    padding: '0 16px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 6,
  },
  controlBar: {
    display: 'flex',
    padding: '0 16px 28px 0',
  },
});

const formatDate = (dateString: string | number | Date) => {
  return moment(new Date(dateString)).format(dateFormats.compact);
};

interface Props extends WithStyles<typeof styles> {
  hideButton: boolean;
  chart: any;
  actions: ActionDto[];
  onSubmitNewAction: any;
  onRemoveAction: any;
  chartFormat: string;
  onChangeChartFormat: any;
  onChangeDataPeriod: any;
  stepDays: number;
  onChangeDataValueType: any;
  dataVisualisationType: string;
}

const MitigationActivityTracker: React.FC<Props> = ({
  hideButton,
  chart,
  actions,
  classes,
  onSubmitNewAction,
  onRemoveAction,
  chartFormat,
  onChangeChartFormat,
  onChangeDataPeriod,
  stepDays,
  onChangeDataValueType,
  dataVisualisationType,
}) => {
  const [openActionsDialog, setOpenActionsDialog] = useState(false);
  const formattedActions = Array.isArray(actions)
    ? actions.map((a) => ({ ...a, date: formatDate(a.date) }))
    : [];

  return (
    <>
      <div style={{ paddingBottom: '10px' }} className={classes.header}>
        <Typography variant="h5" component="span">
          {selectedAlert.value ? 'Alert trends' : 'Mitigation activity tracker'}
        </Typography>
        {!selectedAlert.value && hideButton === false ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setOpenActionsDialog(true)}
          >
            Manage actions
          </Button>
        ) : null}
      </div>
      <div className={classes.controlBar}>
        <Spacer />
        <ChartFormatToggle
          value={chartFormat}
          onChange={(newFormat) => onChangeChartFormat(newFormat)}
        />
        <ChartDataVisualisationTypeToggle
          value={dataVisualisationType}
          onChange={(newDataType) => onChangeDataValueType(newDataType)}
        />
        <ChartDataPeriodSelector
          value={stepDays}
          onChange={(newStepDays) => onChangeDataPeriod(newStepDays)}
        />
      </div>
      <TrackerChart
        chartShape={chart.chartShape}
        chartData={
          chartFormat === CHART_FORMATS.Percentages
            ? chart.percentagesChartData
            : chart.totalCountsChartData
        }
        showTooltip={true}
        showLegend={true}
        actions={formattedActions}
        isPercentages={chartFormat === CHART_FORMATS.Percentages}
      ></TrackerChart>
      <TrackerActionsDialog
        open={openActionsDialog}
        onClose={() => setOpenActionsDialog(false)}
        actions={actions}
        onSubmit={onSubmitNewAction}
        onRemove={onRemoveAction}
      />
    </>
  );
};

MitigationActivityTracker.propTypes = {
  stepDays: PropTypes.number.isRequired,
  onChangeDataValueType: PropTypes.func.isRequired,
  dataVisualisationType: PropTypes.string.isRequired,
};

export default withStyles(styles)(MitigationActivityTracker);
