// @ts-nocheck
import React, { useState, useImperativeHandle, useEffect } from 'react';
import {
  UNMAPPED_ITEMS_POOL,
  ENTITY_TYPES,
  ENTITY_NAMES,
  ITEMS,
  EXTRA_ITEMS_POOL,
} from './constants';
import { MovableItem } from './movable-item';
import { ExtrasPool, HeadersPool } from './items-pool';
import { Card } from './card';
import './upload-mapper.css';
import SearchBar from '../../search/SearchBar';
import { includesText } from '../../../helpers/data-helper';
import api from '../../../services/ApiInstance';
import ApiRoutes from '../../../services/ApiRoutes';
import { IsCardNameValid, MapperCards } from './mapper-data';

const TABS = [
  { name: 'All', category: 0, selected: false },
  {
    name: ENTITY_NAMES.Reports,
    category: ENTITY_TYPES.Reports,
    selected: true,
  },
  {
    name: ENTITY_NAMES.Locations,
    category: ENTITY_TYPES.Locations,
    selected: false,
  },
  {
    name: ENTITY_NAMES.Facilities,
    category: ENTITY_TYPES.Facilities,
    selected: false,
  },
  {
    name: ENTITY_NAMES.AssociatedReports,
    category: ENTITY_TYPES.AssociatedReports,
    selected: false,
  },
  {
    name: ENTITY_NAMES.Organisations,
    category: ENTITY_TYPES.Organisations,
    selected: false,
  },
];

const UploadMapper = React.forwardRef(
  ({ initialItems, client, selectedEntityTypes }, ref) => {
    const [items, setItems] = useState([]);
    const [category, setCategory] = useState(ENTITY_TYPES.Reports);
    const [tabs, setTabs] = useState(TABS);
    const [headersSearchText, setHeadersSearchText] = useState('');
    const [mappedSearchText, setMappedSearchText] = useState('');
    const [allClientsValueMappingRules, setAllClientsValueMappingRules] =
      useState({});

    useEffect(() => {
      const loadClientValueMappingRules = () => {
        if (client && !allClientsValueMappingRules[client.id]) {
          api
            .get(ApiRoutes.getFileValueMappingRules, {
              params: { clientId: client.id },
            })
            .then((res) => {
              const selectedClientRules = res ? res : {};
              setAllClientsValueMappingRules((prevState) => ({
                ...prevState,
                [client.id]: selectedClientRules,
              }));
            });
        }
      };
      loadClientValueMappingRules();
    }, [client]);

    useEffect(() => {
      const validItems = initialItems.map((item) => {
        if (!IsCardNameValid(item.card)) {
          item.card = UNMAPPED_ITEMS_POOL;
        }
        return item;
      });
      setItems(validItems ? validItems : ITEMS);
    }, [initialItems]);

    useImperativeHandle(
      ref,
      () => ({
        getMappedItems: () => {
          return items.filter((i) => i.card !== UNMAPPED_ITEMS_POOL);
        },
        getItems: () => {
          return items;
        },
        getValueMappingRules: () => {
          return client ? allClientsValueMappingRules[client.id] : {};
        },
        getRules: () => {
          const rules = {};
          items
            .filter((i) => i.card !== UNMAPPED_ITEMS_POOL)
            .forEach((i) => {
              const { card, name } = i;
              if (rules[card]) {
                rules[card] = rules[card] + ',' + name;
              } else {
                rules[card] = name;
              }
              return rules;
            });
          return Object.keys(rules).map((key) => {
            return {
              source: rules[key],
              mappedTo: key,
            };
          });
        },
        isValid: () => {
          const requiredCards = getRequiredCards();
          return requiredCards.every((card) =>
            items.some((item) => item.card === card.name)
          );
        },
      }),
      [items, allClientsValueMappingRules, selectedEntityTypes]
    );

    function getSelectedClientValueMappingRules() {
      if (client) {
        const selectedClientRules = allClientsValueMappingRules[client.id];
        return selectedClientRules ? selectedClientRules : {};
      }
      return {};
    }

    function getValueMappingRulesByProperty(propertyName) {
      const clientValueMappingRules = getSelectedClientValueMappingRules();
      return clientValueMappingRules
        ? clientValueMappingRules[propertyName]
        : {};
    }

    function updateValueMappingRules(propertyName, rules) {
      if (client) {
        setAllClientsValueMappingRules((prevState) => {
          const clientValueMappingRules = prevState[client.id];
          return {
            ...prevState,
            [client.id]: {
              ...clientValueMappingRules,
              [propertyName]: rules,
            },
          };
        });
      }
    }

    function moveCardHandler(dragIndex, hoverIndex) {
      const dragItem = items[dragIndex];

      if (dragItem) {
        setItems((prevState) => {
          const coppiedStateArray = [...prevState];

          // remove item by "hoverIndex" and put "dragItem" instead
          const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

          // remove item by "dragIndex" and put "prevItem" instead
          coppiedStateArray.splice(dragIndex, 1, prevItem[0]);
          return coppiedStateArray;
        });
      }
    }

    function cardItem(cardName, searchText = '') {
      return items
        .filter(
          (item) =>
            item.card === cardName &&
            (!searchText || includesText(item.name, searchText))
        )
        .map((item, index) => (
          <MovableItem
            key={`mi-${index}`}
            name={item.name}
            currentCardName={item.card}
            setItems={setItems}
            index={index}
            moveCardHandler={moveCardHandler}
          />
        ));
    }

    function updateTabs(value) {
      setCategory(value.category);
      setTabs((prevState) => {
        return prevState.map((e) => {
          return {
            ...e,
            selected: e.name === value.name,
          };
        });
      });
    }

    function isCardVisible(card, category) {
      return (
        category === 0 ||
        (card.categories && card.categories.includes(category))
      );
    }

    function isCardFound(card, searchText) {
      if (!searchText) {
        return true;
      }
      const hasCardText =
        includesText(card.name, searchText) ||
        includesText(card.title, searchText);
      if (hasCardText) {
        return true;
      }
      const item = items.find((i) => i.card === card.name);
      return item && includesText(item.name, searchText);
    }

    function getRequiredCards() {
      return MapperCards.filter((card) =>
        card.requiredFor.some((entityType) =>
          selectedEntityTypes.includes(entityType)
        )
      );
    }

    const requiredCards = getRequiredCards();

    return (
      <div className="container">
        <HeadersPool>
          <SearchBar onChange={setHeadersSearchText} />
          {cardItem(UNMAPPED_ITEMS_POOL, headersSearchText)}
        </HeadersPool>

        <div className="mapper-row-container mapper-container-border">
          <div className="tabbed">
            <ul>
              {tabs.map((value, index) => {
                return (
                  <li
                    key={index}
                    className={value.selected ? 'active' : ''}
                    onClick={() => updateTabs(value)}
                  >
                    {value.name}
                  </li>
                );
              })}
            </ul>
          </div>
          <SearchBar onChange={setMappedSearchText} />
          {MapperCards.filter(
            (c) =>
              isCardVisible(c, category) && isCardFound(c, mappedSearchText)
          ).map((card, index) => {
            return (
              <Card
                key={index}
                title={card.title}
                name={card.name}
                itemised={card.itemised}
                onChangeValueMappingRules={updateValueMappingRules}
                valueMappingRules={getValueMappingRulesByProperty(card.name)}
                isValueMapperDisabled={!client}
                required={requiredCards.some((c) => c.name === card.name)}
              >
                {cardItem(card.name)}
              </Card>
            );
          })}
        </div>

        <ExtrasPool>{cardItem(EXTRA_ITEMS_POOL)}</ExtrasPool>
      </div>
    );
  }
);

UploadMapper.displayName = 'UploadMapper';

export default UploadMapper;
