import React, { useState, useEffect, useRef } from 'react';
import { TextField, Chip } from '@material-ui/core';
import { OrganisationAdditionalTopics, OrganisationAdditionalTopicsDto, OrganisationDto } from '../../api/apiDtos';

interface Props {
  option: string;
  setTopicsAndLabels: (topicsAndLabels: Record<string, any>) => void;
  topicsAndLabels: OrganisationAdditionalTopicsDto[];
  organisation: OrganisationDto;
}

const chipCompStyles = {
  display: 'flex',
  alignContent: 'center',
  gap: '1em',
};

export const CustomLabelComponent: React.FC<Props> = ({ option, setTopicsAndLabels, topicsAndLabels, organisation }) => {
  const [isAddingLabel, setIsAddingLabel] = useState(false);
  const [label, setLabel] = useState(() => {
    if (topicsAndLabels) {
      for (const x of topicsAndLabels) {
        if(x.additionalTopicName === option){
          return x.customLabel;
        }
      }
    }
    return '';
  });
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isAddingLabel && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingLabel]);

  const handleLabelConfirm = (event: any) => {
    if ('key' in event && event.key !== 'Enter') return;

    setIsAddingLabel(false);
    let newTopicsAndLabels = topicsAndLabels ?? [];

    if (topicsAndLabels instanceof Array) {
      let found = false;
      for (const x of topicsAndLabels) {
        if (x.additionalTopicName === option) {
          found = true;
          x.customLabel = label;
        }
      }

      newTopicsAndLabels = Object.assign([], [...topicsAndLabels]);
      if (!found) {
        newTopicsAndLabels.push({
            customLabel: label,
            additionalTopicName: option,
            organisationId: organisation.id,
            id: '00000000-0000-0000-0000-000000000000',
            additionalTopic: undefined,
            additionalTopicId: '00000000-0000-0000-0000-000000000000',
          } as OrganisationAdditionalTopics);
      }   
    } else {
      newTopicsAndLabels.push({
        customLabel: label,
            additionalTopicName: option,
            organisationId: organisation.id,
            id: '00000000-0000-0000-0000-000000000000',
            additionalTopic: undefined,
            additionalTopicId: '00000000-0000-0000-0000-000000000000',
          } as OrganisationAdditionalTopics);
    }

    setTopicsAndLabels(newTopicsAndLabels);
  };

  const handleDelete = () => {
    let newTopicsAndLabels = topicsAndLabels ?? [];

    for (const x of topicsAndLabels) {
      if (x.additionalTopicName === option) {
        x.customLabel = '';
      }
    }

    newTopicsAndLabels = Object.assign([], [...topicsAndLabels]);
    setTopicsAndLabels(newTopicsAndLabels);
  };

  return (
    <div style={chipCompStyles}>
      {option}
      {topicsAndLabels[option]?.customLabel !== '' ? (
        <Chip
          style={{ marginLeft: '0.5em' }}
          size="small"
          label={
            isAddingLabel ? (
              <TextField
                value={label}
                fullWidth
                inputRef={inputRef}
                label={label}
                variant="outlined"
                onChange={(e) => setLabel(e.target.value)}
                onBlur={handleLabelConfirm}
                onKeyDown={handleLabelConfirm}
              />
            ) : label ? (
              label
            ) : (
              'Add Custom Label?'
            )
          }
          clickable
          color="primary"
          onDelete={() => handleDelete()}
          onClick={() => setIsAddingLabel(true)}
          onKeyDown={(e) => {
            if (e.key === 'Backspace') {
              e.stopPropagation();
            }
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
