import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  fadeIn: {
    animation: '$fadeIn 1s ease-out forwards',
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  tableContainer: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontWeight: 500,
    color: 'white',
    background:
      'linear-gradient(to bottom, rgba(217, 73, 54, 1), rgba(217, 73, 54, 0.9))',
    position: 'absolute',
    left: '50px',
    top: '10px',
    border: '1px solid black',
    borderRadius: '16px',
    padding: '10px',
    display: 'flex',
  },
  keys: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontWeight: 500,
    background: 'rgba(100, 100, 100, 0.5)',
    position: 'absolute',
    left: '50px',
    top: '60px',
    border: '1px solid rgba(0, 0, 0, 1)',
    borderRadius: '16px',
    padding: '10px',
    display: 'flex',
  },
  selectedCircle: {
    width: '10px',
    height: '10px',
    backgroundColor: 'rgba(41, 182, 246, 1)',
    border: '1px solid rgba(0, 0, 0, 1)',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
  },
  partiallySelectedCircle: {
    width: '10px',
    height: '10px',
    backgroundColor: 'rgba(0, 128, 0, 1)',
    border: '1px solid rgba(0, 0, 0, 1)',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
  },
  notSelectedCircle: {
    width: '10px',
    height: '10px',
    backgroundColor: 'rgba(255, 235, 59, 1)',
    border: '1px solid rgba(0, 0, 0, 1)',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
  },
  arrowContainer: {
    position: 'absolute',
    left: '1435px',
    top: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    animation: 'bounce 2s infinite',
  },
  arrowHead: {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '15px solid black',
  },
  arrowTail: {
    width: '6px',
    height: '20px',
    backgroundColor: 'black',
    marginTop: '-5px',
  },
});

const AnimatedArrow = () => {
  const classes = useStyles();

  return (
    <div className={classes.arrowContainer}>
      <div className={classes.arrowHead} />
      <div className={classes.arrowTail} />
      <style>
        {`
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(-10);
            }
            25% {
              transform: translateY(-30px);
            }
            75% {
              transform: translateY(-10px);
            }
          }
        `}
      </style>
    </div>
  );
};

function MapClusterSelectionPopUp() {
  const classes = useStyles();

  return (
    <>
      <div className={`${classes.tableContainer} ${classes.fadeIn}`}>
        Select desired clusters then filter reports
      </div>
      <div className={`${classes.keys} ${classes.fadeIn}`}>
        <ul>
          <h1>Key:</h1>
          <br />
          <li>
            <div className={classes.selectedCircle}></div>Selected
          </li>
          <li>
            <div className={classes.partiallySelectedCircle}></div>Partially
            selected
          </li>
          <li>
            <div className={classes.notSelectedCircle}></div>Not selected
          </li>
        </ul>
      </div>
      {/* <div className={`${classes.arrow} ${classes.fadeIn}`}>
        <AnimatedArrow />
      </div> */}
    </>
  );
}

export default MapClusterSelectionPopUp;
