import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  CssBaseline,
  Paper,
  makeStyles,
  CircularProgress,
  Theme,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(4),
    color: '#fff !important',
    backgroundColor: '#D94936 !important',
  },
  lrLogo: {
    marginBottom: '1.9em',
    marginTop: '1.2em',
  },
  resetArea: {
    marginTop: '1em',
    fontSize: '0.9em',
  },
  orangeInput: {
    '& .MuiInput-underline:before': {
      borderBottomColor: '#D94936 !important',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#D94936 !important',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#D94936 !important',
    },
  },
  orangeLabel: {
    color: '#D94936 !important',
  },
}));

const LoginPage: FC = () => {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem('redirectAfterLogin', window.location.pathname);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    loginWithRedirect().catch(() => {
      setLoading(false);
      toast.error('Login failed, please try again.');
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <img
            alt="logo"
            className={classes.lrLogo}
            src="images/logos/logo.png"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleLogin}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Sign in'
            )}
          </Button>
        </Paper>
      </main>
    </React.Fragment>
  );
};

export default LoginPage;
