import React, { useState, useEffect, FC } from 'react';
import {
  Button,
  withStyles,
  Card,
  CardHeader,
  CardContent,
  createStyles,
  WithStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ActionsList from './ActionsList';
import ActionsForm from './ActionsForm';
import ApiRoutes from '../../services/ApiRoutes';
import API from '../../services/ApiInstance';
import { toast } from 'react-toastify';
import { ActionDto, FacilityDto, OrganisationDto } from '../../api/apiDtos';

const styles = createStyles({
  cardHeaderAction: {
    margin: 0,
  },
});

interface Props extends WithStyles<typeof styles> {}

const ActionsManager: FC<Props> = ({ classes }) => {
  const [facilities, setFacilities] = useState<FacilityDto[]>([]);
  const [organisations, setOrganisations] = useState<OrganisationDto[]>([]);
  const [actions, setActions] = useState<ActionDto[]>([]);
  const [creatingNew, setCreatingNew] = useState(false);

  useEffect(() => {
    getActions();
    getOrganisations();
    getFacilities();
  }, []);

  const getActions = async () => {
    const res = await API.get<any, ActionDto[]>(ApiRoutes.getActions);
    setActions(res);
  };

  const getOrganisations = async () => {
    const response = await API.get<any, OrganisationDto[]>(ApiRoutes.orgs);
    setOrganisations(response);
  };

  const getFacilities = async () => {
    const response = await API.get<any, FacilityDto[]>(
      ApiRoutes.getAllFacilities
    );
    setFacilities(response);
  };

  const openNewForm = () => setCreatingNew(true);
  const closeNewForm = () => setCreatingNew(false);

  const onCreateAction = () => {
    toast.success('Action created');
    getActions();
  };

  const onDeleteAction = () => {
    toast.success('Action deleted');
    getActions();
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title="Manage Actions"
        classes={{ action: classes.cardHeaderAction }}
        action={
          <Button color="primary" variant="contained" onClick={openNewForm}>
            <AddIcon />
            Add new action
          </Button>
        }
      />
      <CardContent>
        <ActionsList actions={actions} onDelete={onDeleteAction} />
        {creatingNew && (
          <ActionsForm
            facilities={facilities}
            organisations={organisations}
            handleClose={closeNewForm}
            onCreate={onCreateAction}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ActionsManager);
