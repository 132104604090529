import React, { FC, useRef } from 'react';
import Colours from '../../services/ColourService';
import moment from 'moment';
import { OTHERS } from '../../constants';
import Highcharts, * as HighCharts from 'highcharts';
import HighchartsReact, {
  HighchartsReactProps,
} from 'highcharts-react-official';
import { ChartDataDto, ReportHistoryDto } from '../../api/originalMappings';

const createChartShape = (chartData: any[]) => {
  const chartShape = [];
  chartData.forEach((item) => {
    Object.keys(item.data).forEach((reportType) => {
      if (!chartShape.includes(reportType)) {
        chartShape.push(reportType);
      }
    });
  });
  return chartShape;
};

const getTopReportTypes = (chartData: any[], limit: number) => {
  const reportTypeTotalCounts = {};
  chartData.forEach((item) => {
    Object.entries(item.data).forEach(([reportType, count]) => {
      const totalCount = reportTypeTotalCounts[reportType];
      if (totalCount) {
        reportTypeTotalCounts[reportType] = totalCount + count;
      } else {
        reportTypeTotalCounts[reportType] = count;
      }
    });
  });

  return Object.keys(reportTypeTotalCounts)
    .sort((a, b) => reportTypeTotalCounts[b] - reportTypeTotalCounts[a])
    .slice(0, limit);
};

const aggregateMinorReportTypes = (
  chartData: ChartDataDto[],
  aggregationThreshold: number
): ChartDataDto[] => {
  const topReportTypes = getTopReportTypes(chartData, aggregationThreshold);
  return chartData.map((item) => {
    const processed = { date: item.date, data: {} };
    topReportTypes.forEach((type) => {
      const count = item.data[type];
      processed.data[type] = count ? count : 0;
    });
    const othersCount = Object.keys(item.data)
      .filter((type) => !topReportTypes.includes(type))
      .reduce((sum, current) => sum + item.data[current], 0);
    processed.data[OTHERS] = othersCount;
    return processed;
  });
};

const formatDate = (date: string | number | Date) => new Date(date);

const createHighchartsSeriesData = (
  chartData: ChartDataDto[]
): Highcharts.SeriesAreaOptions[] => {
  if(chartData.length === 0) return [];
  const keys = Object.keys(chartData[0].data).filter((x) => x !== 'date'); //only need to extract the keys apart from 'date'

  // Map over the keys array to create a new array of objects.
  const seriesDataKeys = keys.map((key) => ({
    name: key,
  }));

  const formatReportTypeCounts = (
    chartData: ChartDataDto[],
    key: string
  ): number[] => {
    return chartData.map((x) => (x.data[key] ? x.data[key] : 0));
  };

  return seriesDataKeys.map((x) => ({
    ...x,
    type: 'area',
    data: formatReportTypeCounts(chartData, x.name),
  }));
};

interface Props extends HighchartsReactProps {
  data: ReportHistoryDto;
  tooltip: boolean;
  aggregationEnabled: boolean;
  aggregationThreshold: number;
  legend: boolean;
  axisY: string;
}

export const ReportHistoryChart: FC<Props> = ({
  data,
  legend,
  tooltip,
  aggregationEnabled,
  aggregationThreshold,
  axisY = 'Total Reported',
  ...rest
}) => {
  const chartData = aggregationEnabled
    ? aggregateMinorReportTypes(data.chartData, aggregationThreshold)
    : data.chartData;
  const formattedChartData: ChartDataDto[] = chartData.map((d) => ({
    ...d,
    date: formatDate(d.date),
  }));
  const chartShape = createChartShape(chartData);
  const colours = Colours.materialColors();
  const palette = chartShape.reduce((palette, c) => {
    palette[c] = colours.next();
    return palette;
  }, {});

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const minDate = new Date(chartData.at(0)?.date);
  const maxDate = new Date(chartData.at(-1)?.date);

  const chartOptions: HighCharts.Options = {
    chart: {
      type: 'area',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: 'Report Frequencies Over Time',
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%d %b %Y', //ex- 01 Jan 2016
      },
    },
    yAxis: {
      title: {
        text: 'Number of Reports',
      },
      type: 'linear',
    },
    plotOptions: {
      area: {
        pointStart: Date.UTC(
          minDate.getFullYear(),
          minDate.getMonth(),
          minDate.getDate()
        ),
        pointInterval: 1,
        pointIntervalUnit: 'day',
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series: createHighchartsSeriesData(formattedChartData),
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      ref={chartComponentRef}
      {...rest}
    />
  );
};
