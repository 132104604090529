import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ApiRoutes from '../../services/ApiRoutes';
import API from '../../services/ApiInstance';
import moment from 'moment';
import FilterDrawer from '../filters/FilterDrawer';
import ReportSortWidget from '../widgets/ReportSortWidget';
import { trackPage } from '../../services/analytics/AnalyticsService';
import TimeServiceProvider from '../../services/TimeService';
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import ReportHistory from '../widgets/ReportHistory';
import { widgetPropTypes } from '../widgets-manager-page/widgets-management-constants';
import './dashboard-styles.css';
import TopicTrends from '../widgets/TopicTrends';
import AssociatedReportsCostCard from './AssociatedReportsCostCard';
import ReportsByDates from '../widgets/ReportsByDates';
import ReportsAveragesByClassification from '../widgets/ReportsAveragesByClassification';
import RootCauseBreakdowns from '../widgets/RootCauseBreakdowns';
import PriorityRootCauseClusters from '../widgets/PriorityRootCauseClusters';
import PreventiveActionsGuidance from '../widgets/preventive-actions-guidance/PreventiveActionsGuidance';
import RootCauseToTopicCorrelations from '../widgets/RootCauseToTopicCorrelations';
import MapWidget from '../widgets/MapWidget';
import PriorityHazardClusters from '../widgets/PriorityHazardClusters';
import ReportsTableWidget from '../widgets/ReportsTableWidget';
import AssociatedReportsTableWidget from '../widgets/AssociatedReportsTableWidget';
import LifeSavingRulesWidget from '../widgets/life-saving-rules/LifeSavingRulesWidget';
import { WidgetCode } from '../widgets/widgetCodes';
import { SizeMe } from 'react-sizeme';
import { TableColumnsProvider } from '../../context/TableColumnsContext';
import { NotificationTable } from '../../components/widgets/NotificationTable';
import { Grid } from '@material-ui/core';

const filterDrawerWidth = 300;

class Dashboard extends Component {
  layoutProps = {
    className: 'layout',
    cols: { lg: 3, md: 3, sm: 1, xs: 1, xxs: 1 },
    rowHeight: 500,
    isDraggable: false,
    isResizable: false,
  };

  constructor(props) {
    super(props);
    trackPage('dashboard');
    this.timeService = TimeServiceProvider();

    this.state = {
      timeDomain: this.timeService.domain,
      layout: [],
      widgets: [],
      tableColumnsMap: {
        reportTableColumns: [],
        reportTableColumnsExpanded: [],
        associatedReportTableColumns: [],
        associatedReportTableColumnsExpanded: [],
      },
      isFilterDrawerVisible: true,
    };
  }

  componentDidMount() {
    this.loadDashboardConfiguration();
    this.loadWidgetsConfiguration();
  }

  loadDashboardConfiguration() {
    this.setState({
      widgets: [],
      layout: [],
    });

    API.get(ApiRoutes.getOwnDashboardConfiguration).then(
      (response) => {
        if (response) {
          const { layout, widgets } = response;
          this.setState({
            widgets,
            layout,
          });
        }
      },
      (err) => {
        console.log('error encountered: ' + err);
      }
    );
  }

  loadWidgetsConfiguration() {
    this.setState({
      widgetSettings: [],
    });

    API.get(ApiRoutes.getOwnWidgetsConfiguration).then(
      (response) => {
        if (response) {
          const { widgetSettings } = response;
          const reportTableColumns = this.getSettingsTableColumns(
            widgetSettings,
            WidgetCode.reportTable
          );
          const reportTableColumnsExpanded = this.getSettingsTableColumns(
            widgetSettings,
            WidgetCode.reportTableExpanded
          );
          const associatedReportTableColumns = this.getSettingsTableColumns(
            widgetSettings,
            WidgetCode.associatedReportTable
          );
          const associatedReportTableColumnsExpanded =
            this.getSettingsTableColumns(
              widgetSettings,
              WidgetCode.associatedReportTableExpanded
            );
          this.setState({
            widgetSettings: widgetSettings,
            tableColumnsMap: {
              reportTableColumns,
              reportTableColumnsExpanded,
              associatedReportTableColumns,
              associatedReportTableColumnsExpanded,
            },
          });
        }
      },
      (err) => {
        console.log('error encountered: ' + err);
      }
    );
  }

  getSettingsTableColumns(widgetSettings, code) {
    const tableProps = widgetSettings.find((w) => w.code === code);
    if (tableProps && tableProps.usedProps) {
      return this.getColumnsFromWidgetSettingProps(tableProps.usedProps);
    }
  }

  getWidgetSettingProps(code) {
    const data = {};
    const widgetSetting = this.state.widgetSettings.find(
      (w) => w.code === code
    );
    if (!widgetSetting) return data;

    const usedProps = widgetSetting.usedProps;

    if (!usedProps) return data;

    usedProps.forEach((p) => {
      data[p.code] = p.value;
    });
    return data;
  }

  getColumnsFromWidgetSettingProps(usedProps) {
    return usedProps
      .filter((p) => p.typeName === widgetPropTypes.tableColumn && p.isEnabled)
      .map((widgetProperty) => {
        const column = {
          name: widgetProperty.code,
          label: widgetProperty.value,
          options: {
            sort: true,
          },
        };

        if (widgetProperty.code.toLowerCase().includes('date')) {
          //STUB
          column.options.customBodyRender = (value) =>
            moment(value).format('D/MMM/YY');
        }

        return column;
      });
  }

  goToTrackers = () => {
    this.props.history.push({
      pathname: '/trackers',
      state: { edit: true },
    });
  };

  createLayoutItem(el) {
    const mapped = this.state.widgets.find((w) => w.mappedTo === el.i);
    const isResizable = this.layoutProps.isResizable;
    const className = isResizable
      ? 'grid-widget-item resizable-layout-item'
      : 'grid-widget-item';

    return (
      <div key={el.i} className={className}>
        {mapped && mapped.title && this.renderWidget(mapped)}
      </div>
    );
  }

  renderWidget(widget) {
    const { code } = widget;
    switch (code) {
      case WidgetCode.reportTable:
        return this.renderReportTableWidget();
      case WidgetCode.associatedReportTable:
        return this.renderAssociatedReportTableWidget();
      case WidgetCode.reportHistory:
        return this.renderReportHistoryWidget();
      case WidgetCode.reportsByOrganisationAndTypes:
        return this.renderSortDataWidget();
      case WidgetCode.topicTrends:
        return this.renderTopicTrendsWidget();
      case WidgetCode.costOverTime:
        return this.renderClaimCostWidget();
      case WidgetCode.reportsMap:
        return this.renderReportsLocationWidget();
      case WidgetCode.reportsByDates:
        return this.renderReportsByDates();
      case WidgetCode.reportsAveragesByClassification:
        return this.renderReportsAverageByClassification();
      case WidgetCode.rootCauseBreakdowns:
        return this.renderRootCauseBreakdowns();
      case WidgetCode.priorityRootCauseClusters:
        return this.renderPriorityRootCauseClusters();
      case WidgetCode.preventiveActionsGuidance:
        return this.renderPreventiveActionsGuidance();
      case WidgetCode.rootCauseToTopicCorrelations:
        return this.renderRootCauseToTopicCorrelations();
      case WidgetCode.priorityHazards:
        return this.renderPriorityHazardClusters();
      case WidgetCode.lifeSavingRules:
        return this.renderLifeSavingRules();
      default:
        return null;
    }
  }

  renderReportTableWidget() {
    const settings = this.getWidgetSettingProps(WidgetCode.reportTable);
    return <ReportsTableWidget settings={settings} />;
  }

  renderAssociatedReportTableWidget() {
    const settings = this.getWidgetSettingProps(
      WidgetCode.associatedReportTable
    );
    return <AssociatedReportsTableWidget settings={settings} />;
  }

  renderReportHistoryWidget() {
    const settings = this.getWidgetSettingProps(WidgetCode.reportHistory);
    const rawSettings = this.state.widgetSettings.find(
      (w) => w.code === WidgetCode.reportHistory
    );
    return <ReportHistory settings={settings} rawSettings={rawSettings} />;
  }

  renderSortDataWidget() {
    const reportsByOrganisationsProps = this.getWidgetSettingProps(
      WidgetCode.reportsByOrganisations
    );
    const reportsByTypesProps = this.getWidgetSettingProps(
      WidgetCode.reportsByTypes
    );

    return (
      <ReportSortWidget
        reportsByOrganisationsProps={reportsByOrganisationsProps}
        reportsByTypesProps={reportsByTypesProps}
      />
    );
  }

  renderClaimCostWidget() {
    const settings = this.getWidgetSettingProps(WidgetCode.costOverTime);
    return (
      <AssociatedReportsCostCard
        domain={this.state.timeDomain}
        settings={settings}
      />
    );
  }

  renderTopicTrendsWidget() {
    const settings = this.getWidgetSettingProps(WidgetCode.topicTrends);
    return <TopicTrends limit={4} settings={settings} />;
  }

  renderReportsLocationWidget() {
    const { reportTableColumnsExpanded } = this.state;
    const settings = this.getWidgetSettingProps(WidgetCode.reportsMap);
    return (
      <MapWidget columns={reportTableColumnsExpanded} settings={settings} />
    );
  }

  renderReportsByDates() {
    const settings = this.getWidgetSettingProps(WidgetCode.reportsByDates);
    const rawSettings = this.state.widgetSettings.find(
      (w) => w.code === WidgetCode.reportsByDates
    );
    return <ReportsByDates settings={settings} rawSettings={rawSettings} />;
  }

  renderRootCauseBreakdowns() {
    const settings = this.getWidgetSettingProps(WidgetCode.rootCauseBreakdowns);
    return <RootCauseBreakdowns settings={settings} />;
  }

  renderReportsAverageByClassification() {
    const settings = this.getWidgetSettingProps(
      WidgetCode.reportsAveragesByClassification
    );
    return <ReportsAveragesByClassification settings={settings} />;
  }

  renderPriorityRootCauseClusters() {
    const settings = this.getWidgetSettingProps(
      WidgetCode.priorityRootCauseClusters
    );
    return <PriorityRootCauseClusters settings={settings} limit={4} />;
  }

  renderPreventiveActionsGuidance() {
    const settings = this.getWidgetSettingProps(
      WidgetCode.preventiveActionsGuidance
    );
    return <PreventiveActionsGuidance limit={4} settings={settings} />;
  }

  renderRootCauseToTopicCorrelations() {
    const settings = this.getWidgetSettingProps(
      WidgetCode.rootCauseToTopicCorrelations
    );
    return <RootCauseToTopicCorrelations limit={5} settings={settings} />;
  }

  renderPriorityHazardClusters() {
    const settings = this.getWidgetSettingProps(WidgetCode.priorityHazards);
    return <PriorityHazardClusters settings={settings} limit={4} />;
  }

  renderLifeSavingRules() {
    const settings = this.getWidgetSettingProps(WidgetCode.lifeSavingRules);
    return <LifeSavingRulesWidget settings={settings} />;
  }

  CreateGridLayout = () => {
    return this.state.layout.map((item) => this.createLayoutItem(item));
  };

  onToggleFilterDrawer = (isVisible) => {
    this.setState({ isFilterDrawerVisible: isVisible });
  };

  render() {
    const { layout, isFilterDrawerVisible, tableColumnsMap } = this.state;
    const layouts =
      layout && layout.length > 0
        ? { lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }
        : {};

    return (
      <>
        <FilterDrawer
          width={filterDrawerWidth}
          onCreateTracker={this.goToTrackers}
          onToggle={this.onToggleFilterDrawer}
        />
        <div
          style={{ marginLeft: isFilterDrawerVisible ? filterDrawerWidth : 0 }}
        >
          <TableColumnsProvider value={tableColumnsMap}>
            <Grid container style={{ padding: '10px' }}>
              <NotificationTable />
            </Grid>
            <SizeMe>
              {({ size }) => (
                <ResponsiveGridLayout
                  width={size.width ? size.width : 0}
                  {...this.layoutProps}
                  layouts={layouts}
                >
                  {this.CreateGridLayout()}
                </ResponsiveGridLayout>
              )}
            </SizeMe>
          </TableColumnsProvider>
        </div>
      </>
    );
  }
}

export default withRouter(Dashboard);
