/* eslint-disable react-hooks/exhaustive-deps */
//@ts-nocheck
import React, { FC, useEffect, useState } from 'react';
import {
  Radio,
  RadioGroup,
  CardContent,
  CardHeader,
  Card,
  withStyles,
  FormControlLabel,
  WithStyles,
  createStyles,
} from '@material-ui/core';
import { ReportDatesByTypeChart } from '../charts/ReportDatesByTypeChart';
import ReportDatesByOrganisationChart from '../charts/ReportDatesByOrganisationChart';
import { getSettings } from '../../helpers/data-helper';
import { ReportsByDatesDefaultSettings } from './widgets-default-settings';
import WidgetTitle from '../help-button/widget-title';
import TimeServiceProvider from '../../services/TimeService';
import { WidgetCode } from './widgetCodes';
import { getReportWidgetData } from '../../services/WidgetDataService';
import CircularProgressCentral from '../common/CircularProgressCentral';
import { widgetPropCodes } from '../widgets-manager-page/widgets-management-constants';
import UserPreferenceService from '../../services/UserPreferenceService';
import { ReportDateAggregationDto } from '../../api/originalMappings';
import { filters } from '../../signals/filterService';
import { logDataFetchError, logResponseParseError } from '../../services/utils';
import { WidgetSettingDto } from '../../api/apiDtos';
import { ReportDateDtoSchema } from '../../api/zodSchemas';
import { z } from 'zod';

const styles = createStyles({
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: '10px !important',
    flexGrow: 1,
  },
});

const chartTypes = {
  types: 'types',
  organisations: 'organisations',
};

interface Props extends WithStyles<typeof styles> {
  classes: any;
  settings: WidgetSettingDto;
  rawSettings: WidgetSettingDto;
}

const ReportsByDates: FC<Props> = ({ classes, settings, rawSettings }) => {
  const userPreferenceService = new UserPreferenceService();
  const timeService = TimeServiceProvider();
  const [trendsChartType, setTrendsChartType] = useState();
  const [data, setData] = useState<ReportDateAggregationDto[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleChartChange = (event: any) => {
    setTrendsChartType(event.target.value);
  };

  const getAggregationSettings = (propCode: string | number) => {
    const customSettings = getSettings(settings, ReportsByDatesDefaultSettings);

    const aggregationProp =
      rawSettings &&
      rawSettings.usedProps.find(
        (prop) => prop.code === widgetPropCodes[propCode]
      );
    const aggregationEnabled = !!aggregationProp && aggregationProp.isEnabled;

    const thresholdString = customSettings[propCode];
    let threshold = parseInt(thresholdString);
    if (!Number.isInteger(threshold)) {
      threshold = parseInt(ReportsByDatesDefaultSettings[propCode]);
    }

    return [aggregationEnabled, threshold];
  };

  const { title, info, axisY, organisationsLabel, typesLabel, noDataLabel } =
    getSettings(settings, ReportsByDatesDefaultSettings);

  const [reportTypeAggregationEnabled, reportTypeAggregationThreshold] =
    getAggregationSettings(widgetPropCodes.reportTypeAggregationThreshold);
  const [organisationAggregationEnabled, organisationAggregationThreshold] =
    getAggregationSettings(widgetPropCodes.organisationAggregationThreshold);

  const getDataFromAPI = () => {
    setIsLoading(true);

    getReportWidgetData(WidgetCode.reportsByDates)
      .then((res) => {
        //here zod is used to do a runtime check whether the parsed res from api is the
        //correct format
        const schemaMatch = z.array(ReportDateDtoSchema).safeParse(res);
        if (schemaMatch.success) {
          setData(schemaMatch.data);
        } else {
          logResponseParseError(schemaMatch.error);
        }
      })
      .catch((err) => logDataFetchError(err, 'ReportsByDates'))
      .finally(() => setIsLoading(false));
  };

  useEffect(function getDataOnLoad() {
    timeService.subscribe(getDataFromAPI);

    getDataFromAPI();

    return () => {
      timeService.unsubscribe(getDataFromAPI);
    };
  }, []);

  useEffect(function setTrendsChartTypeOnLoad() {
    const savedChartType = userPreferenceService.get('ReportsByDatesChartType');
    if (savedChartType !== null) {
      setTrendsChartType(savedChartType);
    }
  }, []);

  useEffect(
    function getFilteredData() {
      getDataFromAPI();
    },
    [filters.value]
  );

  useEffect(
    function trendsChartTypeChanged() {
      userPreferenceService.save('ReportsByDatesChartType', trendsChartType);
    },
    [trendsChartType]
  );

  return isLoading || data?.length === 0 ? (
    <CircularProgressCentral />
  ) : (
    <Card variant="outlined" className={classes.card}>
      <CardHeader
        title={<WidgetTitle title='Reports by Type / Organisation' info={info} />}
        titleTypographyProps={{
          variant: 'subtitle1',
        }}
        action={
          <div>
            <RadioGroup
              value={trendsChartType}
              onChange={(event) => handleChartChange(event)}
              row
            >
              <FormControlLabel
                value={chartTypes.organisations}
                control={<Radio color="primary" />}
                label={organisationsLabel}
                labelPlacement="end"
              />
              <FormControlLabel
                value={chartTypes.types}
                control={<Radio color="primary" />}
                label={typesLabel}
                labelPlacement="end"
              />
            </RadioGroup>
          </div>
        }
      />

      <CardContent className={classes.content}>
        {trendsChartType === chartTypes.types ? (
          <ReportDatesByTypeChart
            data={data}
            axisY={axisY}
            noDataLabel={noDataLabel}
            aggregationEnabled={reportTypeAggregationEnabled as boolean}
            aggregationThreshold={reportTypeAggregationThreshold as number}
          />
        ) : (
          <ReportDatesByOrganisationChart
            data={data}
            axisY={axisY}
            noDataLabel={noDataLabel}
            aggregationEnabled={organisationAggregationEnabled as boolean}
            aggregationThreshold={organisationAggregationThreshold as number}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ReportsByDates);
