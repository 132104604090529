import { WidgetSettings } from "../components/widgets/widgets-default-settings";
import { GenericObject } from "./form-data-helper";

export function isObjectEmpty(obj: GenericObject) {
    return !obj || Object.keys(obj).length === 0;
}

export function getFileName(value: string, fileExtension: string, replaceSpaceValue = '_') {
    const extension = fileExtension.startsWith('.') ? fileExtension : '.' + fileExtension;
    return value.replace(/\s/g, replaceSpaceValue) + extension;
}

export function getPdfFileName(value: string) {
    return getFileName(value, '.pdf');
}

//Not sure what the types are for these
export function getSettings(settings: any, defaultSettings: any): any  {
    return {...defaultSettings, ...settings}; //settings will overwrite default settings
}

export function includesText(source: string, value: string) {
	return source !== "" && source.toLowerCase().includes(value.toLowerCase());
}

export const stopPropagation = (e: Event) => e.preventDefault();

export const removeEmptyKeys = (obj: any) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};

// why are we using a regex to determine if a number is positive?
// - unless the 'value' is always of type string
export const isPositiveNumber = (value: string | number) => /^\d+$/.test(value.toString());

export const trimStart = (str: string, value: string) => {
  return str && value && str.startsWith(value) 
    ? str.substring(value.length) 
    : str;
};
