import React, { useState } from 'react';
import {
  Tooltip,
  IconButton,
  withStyles,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LocalComboBox from '../combobox/local-combo-box';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import PropTypes from 'prop-types';
import InfoDialog from '../dialog/InfoDialog';
import { toast } from 'react-toastify';

const styles = {
  dialogActions: {
    display: 'flex',
    justifyContent: 'end',
    padding: '10px 0',
  },
  button: {
    marginLeft: 10,
  },
  formControl: {
    marginBottom: 10,
  },
};

const INVALID_FORM_TEXT =
  'The form could not be submitted. Please, fill the required fields.';

const UpdateFacilityDialog = ({
  facility,
  organisations,
  onUpdate,
  classes,
}) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [postcode, setPostcode] = useState('');
  const [organisation, setOrganisation] = useState(null);
  const [formValidationError, setFormValidationError] = useState(false);

  const onOpen = () => {
    setName(facility.name);
    setPostcode(facility.postcode ? facility.postcode : '');
    setOrganisation(facility.organisation);
    setOpen(true);
  };

  const close = () => setOpen(false);

  const isFormValid = () => name && organisation;

  const submit = (event) => {
    event.preventDefault();
    if (!isFormValid()) {
      setFormValidationError(true);
      return;
    }
    const updatedFacility = {
      ...facility,
      name,
      postcode,
      organisation,
    };
    api.put(ApiRoutes.updateFacility, updatedFacility).then(() => {
      toast.success('Facility updated');
      close();
      onUpdate();
    });
  };

  return (
    <>
      <Tooltip title="Update facility">
        <IconButton color="primary" onClick={onOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
        <DialogTitle>{`Update Facility ${facility.localId}`}</DialogTitle>
        <DialogContent>
          <form onSubmit={submit}>
            <TextField
              value={name}
              required
              variant="outlined"
              label="Name"
              fullWidth
              onChange={(e) => setName(e.target.value)}
              className={classes.formControl}
            />
            <TextField
              value={postcode}
              variant="outlined"
              label="Postcode"
              fullWidth
              onChange={(e) => setPostcode(e.target.value)}
              className={classes.formControl}
            />
            <LocalComboBox
              options={organisations}
              value={organisation}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(e, value) => setOrganisation(value)}
              customLabel="Organisation *"
            />
            <div className={classes.dialogActions}>
              <Button
                type="submit"
                onClick={submit}
                color="primary"
                variant="contained"
              >
                Submit
              </Button>
              <Button
                onClick={close}
                color="primary"
                variant="outlined"
                className={classes.button}
              >
                Cancel
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {formValidationError && (
        <InfoDialog
          open={formValidationError}
          onClose={() => setFormValidationError(false)}
          text={INVALID_FORM_TEXT}
        />
      )}
    </>
  );
};

UpdateFacilityDialog.propTypes = {
  facility: PropTypes.object.isRequired,
  organisations: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default withStyles(styles)(UpdateFacilityDialog);
