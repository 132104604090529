import React, { FC, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { Chip, Button, makeStyles } from '@material-ui/core';
import API from '../../services/ApiInstance';
import apiRoutes from '../../services/ApiRoutes';
import NewUserDialog from './NewUserDialog';
import EditUserDialog from './EditUserDialog';
import { PERMISSIONS, Roles } from '../../constants';
import { trackPage } from '../../services/analytics/AnalyticsService';
import { toast } from 'react-toastify';
import useAuthorization from '../../hooks/useAuthorisationManager';
import { UserDto } from '../../api/originalMappings';
import useLoggedInUser from '../../useLoggedInUser';

const styles = makeStyles({
  root: {
    width: '100%',
    marginTop: '1em',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  rootDiv: {
    padding: '0 1em',
  },
});

const UserManagement: FC = () => {
  const classes = styles();
  const [users, setUsers] = useState<UserDto[]>();
  const [roles, setRoles] = useState<Roles[]>();
  const { hasPermission } = useAuthorization();
  const { currentUser, setCurrentUser } = useLoggedInUser();

  useEffect(() => {
    async function OnInit() {
      trackPage('user management');
      try {
        await fetchUserList();
        await fetchRolesList();
      } catch (error) {
        console.log(error);
      }
    }
    OnInit();
  }, []);

  async function fetchUserList() {
    const usersIn = await API.get<any, UserDto[]>(apiRoutes.userList);
    setUsers(usersIn);
  }

  async function fetchRolesList() {
    const rolesIn = await API.get<any, Roles[]>(apiRoutes.roles);
    setRoles(rolesIn);
  }

  async function onEdit() {
    toast.success('User updated');
    await fetchUserList();
  }

  async function onCreate() {
    await fetchUserList();
    toast.success('User created');
  }

  function canEditUser(user) {
    const roles = user.roleLevel > currentUser.userRole.level;
    const hasRolePermission = hasPermission(PERMISSIONS.User.Update);

    return roles && hasRolePermission;
  }

  return (
    <div className={classes.rootDiv}>
      {roles && <NewUserDialog roles={roles} onSuccess={onCreate} />}
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">User Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Organisations</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Email</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Role</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Phone</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Is Active</Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users.map((user) => {
                return (
                  <TableRow key={user.id}>
                    <TableCell component="th" scope="user">
                      {user.username}
                    </TableCell>
                    <TableCell>
                      <div>
                        {user.organisations.slice(0, 3).map((org, idx) => (
                          <Chip
                            key={`${org.id}-${idx}`}
                            label={org.name}
                            color="primary"
                            size="small"
                          />
                        ))}
                        {user.organisations.length > 3 ? (
                          <Button color="primary" size="small">
                            + {user.organisations.length - 3}
                          </Button>
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.roleName}</TableCell>
                    <TableCell>
                      {user.phone == null ? '' : user.phone}
                    </TableCell>
                    <TableCell>{user.isActive ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      {user && canEditUser(user) && (
                        <EditUserDialog
                          user={user}
                          roles={roles}
                          onSuccess={onEdit}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default UserManagement;
