//@ts-nocheck
import React, { Component, FC, useEffect, useState } from 'react';
import {
  Radio,
  RadioGroup,
  CardContent,
  CardHeader,
  Card,
  CardActions,
  FormControlLabel,
} from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import RootCauseBreakdownsBySubcategoryChart from '../charts/root-cause-breakdowns/RootCauseBreakdownsBySubcategoryChart';
import RootCauseBreakdownsByOrganisationChart from '../charts/root-cause-breakdowns/RootCauseBreakdownsByOrganisationChart';
import { getSettings } from '../../helpers/data-helper';
import WidgetTitle from '../help-button/widget-title';
import { RootCauseBreakdownsDefaultSettings } from './widgets-default-settings';
import TimeServiceProvider from '../../services/TimeService';
import { WidgetCode } from './widgetCodes';
import { getReportWidgetData } from '../../services/WidgetDataService';
import CircularProgressCentral from '../common/CircularProgressCentral';
import UserPreferenceService from '../../services/UserPreferenceService';
import { logDataFetchError, logResponseParseError } from '../../services/utils';
import { WidgetSettingDto } from '../../api/apiDtos';
import { RootCauseClassificationAggregationDto } from '../../api/originalMappings';
import { RootCauseClassficationDtoSchema } from '../../api/zodSchemas';
import { z } from 'zod';
import { filters } from '../../signals/filterService';

const styles = {
  chartTypeForm: {
    display: 'flex',
    alignItems: 'center',
  },
  chartTypeLabel: {
    marginRight: 16,
  },
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
};

const chartTypes = {
  subcategories: 'subcategories',
  organisations: 'organisations',
};

interface Props extends WithStyles<typeof styles> {
  settings: WidgetSettingDto;
}

const RootCauseBreakdowns: FC<Props> = ({ classes, settings }) => {
  const userPreferenceService = new UserPreferenceService();
  const timeService = TimeServiceProvider();
  const [trendsChartType, setTrendsChartType] = useState();
  const [data, setData] = useState<RootCauseClassificationAggregationDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { title, info, organisationsLabel, typesLabel, axisY, noDataLabel } =
    getSettings(settings, RootCauseBreakdownsDefaultSettings);

  const handleChartChange = (event: any) => {
    setTrendsChartType(event.target.value);
  };

  const getDataFromAPI = () => {
    setIsLoading(true);
    getReportWidgetData(WidgetCode.rootCauseBreakdowns)
      .then((res) => {
        //here zod is used to do a runtime check whether the parsed res from api is the
        //correct format
        const schemaMatch = z
          .array(RootCauseClassficationDtoSchema)
          .safeParse(res);
        if (schemaMatch.success) {
          setData(schemaMatch.data);
        } else {
          logResponseParseError(schemaMatch.error);
        }
      })
      .catch((err) => logDataFetchError(err, 'RootCauseBreakdowns'))
      .finally(() => setIsLoading(false));
  };

  useEffect(function setSavedChartTypeOnLoad() {
    const savedChartType = userPreferenceService.get(
      'RootCauseBreakdownsChartType'
    );
    if (savedChartType !== null) {
      setTrendsChartType(savedChartType);
    } else {
      setTrendsChartType(chartTypes.organisations);
    }
  }, []);

  useEffect(function getDataOnLoad() {
    let isMounted = true;

    timeService.subscribe(getDataFromAPI);

    const fetchData = async () => {
      getDataFromAPI();
      if (!isMounted) {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      timeService.unsubscribe(getDataFromAPI);
    };
  }, []);

  useEffect(
    function getRootCauseBreakdownWhenFilterChanges() {
      let isMounted = true;

      const fetchData = async () => {
        getDataFromAPI();
        if (!isMounted) {
          setIsLoading(false);
        }
      };

      fetchData();

      return () => {
        isMounted = false;
      };
    },
    [filters.value]
  );

  return isLoading ? (
    <CircularProgressCentral />
  ) : (
    <Card variant="outlined" className={classes.card}>
      <CardHeader
        title={<WidgetTitle title={title} info={info} />}
        titleTypographyProps={{
          variant: 'subtitle1',
        }}
        action={
          <div className={classes.chartTypeForm}>
            <div className={classes.chartTypeLabel}>Group by:</div>
            <RadioGroup
              value={trendsChartType}
              onChange={(event) => handleChartChange(event)}
              row
            >
              <FormControlLabel
                value={chartTypes.organisations}
                control={<Radio color="primary" />}
                label={organisationsLabel}
                labelPlacement="end"
              />
              <FormControlLabel
                value={chartTypes.subcategories}
                control={<Radio color="primary" />}
                label={typesLabel}
                labelPlacement="end"
              />
            </RadioGroup>
          </div>
        }
      />

      <CardContent className={classes.cardContent}>
        {trendsChartType === chartTypes.subcategories ? (
          <RootCauseBreakdownsBySubcategoryChart
            data={data}
            axisY={axisY}
            noDataLabel={noDataLabel}
          />
        ) : (
          <RootCauseBreakdownsByOrganisationChart
            data={data}
            axisY={axisY}
            noDataLabel={noDataLabel}
          />
        )}
      </CardContent>
      <CardActions />
    </Card>
  );
};

export default withStyles(styles)(RootCauseBreakdowns);
