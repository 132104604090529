// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  withStyles,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ValidationService from '../../services/authentication/ValidationService';
import ApiRoutes from '../../services/ApiRoutes';
import API from '../../services/ApiInstance';
import LocalComboBox from '../combobox/local-combo-box';
import InfoDialog from '../dialog/InfoDialog';
import ClientComboBox from '../combobox/client-combo-box';
import {ROLES} from '../../constants';
import PropTypes from 'prop-types';
import AdditionalTopicsComboBox from '../combobox/additional-topics-combo-box';
import useAuthorization from '../../hooks/useAuthorisationManager';

const styles = {
  button: {
    marginBottom: '1.5em',
  },
  control: {
    marginBottom: '1em',
  },
};

const FORM_ERROR_TEXT =
  'The form could not be submitted - please fill all the required fields.';

function NewOrganisationDialog({
  industrySectors,
  locations,
  onSuccess,
  classes,
}) {
  const { hasRole } = useAuthorization();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [parent, setParent] = useState(null);
  const [industrySector, setIndustrySector] = useState(null);
  const [location, setLocation] = useState(null);
  const [additionalTopics, setAdditionalTopics] = useState(null);
  const [topicsIsOpen, setTopicsIsOpen] = useState(false);
  const [validationErrorText, setValidationErrorText] = useState('');
  const [openValidationError, setOpenValidationError] = useState(false);
  const [loading, setLoading] = useState(false);
  const validator = new ValidationService();
  const isSuperUser = hasRole(ROLES.SuperUser);
  const [configuredTopics, setConfiguredTopics] = useState([]);

  const industrySectorRequired = () =>
    isSuperUser && parent && parent.level === 0;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName('');
    setParent(null);
    setIndustrySector(null);
    setLocation(null);
    setAdditionalTopics(null);
  };

  useEffect(() => {
    async function onInit() {
      await getTopics();
    }
    onInit();
  }, []);

  async function getTopics() {
    try {
      const result = await API.get(
        ApiRoutes.getTopics
      );
      if (result?.length) {
        setConfiguredTopics(result);
      } else {
        setConfiguredTopics([]);
      }
    } catch (error) {
      console.error('Failed to fetch topics: ', error);
    }
  }

  const submit = () => {
    const requirements = [
      ValidationService.validators.required(name),
      ValidationService.validators.required(parent),
    ];
    if (industrySectorRequired()) {
      requirements.push(ValidationService.validators.required(industrySector));
    }
    const isValid = validator.validate(...requirements);

    if (!isValid) {
      setValidationErrorText(FORM_ERROR_TEXT);
      setOpenValidationError(true);
    } else {
      setLoading(true);
      const newOrg = { name, parentId: parent.id, additionalTopics: {} };
      if (industrySectorRequired()) {
        newOrg.industrySectorId = industrySector.id;
      }
      if (location) {
        newOrg.locationId = location.id;
      }

      if (additionalTopics?.length) {
        newOrg.additionalTopics = Object.entries(additionalTopics).map(
          ([key, value]) => ({
            Id: '00000000-0000-0000-0000-000000000000', //set to empty guid for new org topics.
            OrganisationId:  '00000000-0000-0000-0000-000000000000', //set to empty guid for a new organisation.
            AdditionalTopicName: value.additionalTopicName,
            CustomLabel: value.customLabel,
            additionalTopicId: configuredTopics?.find((item) => item.name === value.additionalTopicName)?.id,
            additionalTopic: value.additionalTopic,
          })
        );
      } else {
        newOrg.additionalTopics = [];
      }

      API.post(ApiRoutes.addOrganisation, newOrg)
        .then(() => {
          setLoading(false);
          handleClose();
          onSuccess();
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          handleClose();
        });
    }
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={handleClickOpen}
        className={classes.button}
      >
        <AddIcon />
        Add new organisation
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>New organisation</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            required
            autoFocus
            fullWidth
            variant="outlined"
            className={classes.control}
          />
          <ClientComboBox
            value={parent}
            onChange={(event, value) => setParent(value)}
            customLabel="Parent *"
            className={classes.control}
          />
          {industrySectorRequired() && (
            <LocalComboBox
              options={industrySectors}
              value={industrySector}
              onChange={(event, value) => setIndustrySector(value)}
              getOptionLabel={(sector) => sector.name}
              customLabel="Industry sector *"
              className={classes.control}
            />
          )}
          <LocalComboBox
            options={locations}
            value={location}
            onChange={(event, value) => setLocation(value)}
            getOptionLabel={(loc) => loc.name}
            customLabel="Location"
            className={classes.control}
          />
          {isSuperUser && <AdditionalTopicsComboBox
            customLabel="Additional Topics"
            options={configuredTopics.map(item => item.name) || []}
            open={topicsIsOpen}
            onOpen={() => setTopicsIsOpen(true)}
            onClose={() => setTopicsIsOpen(false)}
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) => option === value}
            selectedTopics={additionalTopics ? additionalTopics.map(item => item.additionalTopicName) : []}
            topicsAndLabels={additionalTopics ? additionalTopics : []}
            setAdditionalTopics={setAdditionalTopics}
            className={classes.control}
            multiple
          />}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={submit}
            disabled={loading}
            variant="contained"
          >
            Create
          </Button>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {openValidationError && (
        <InfoDialog
          open={openValidationError}
          onClose={() => setOpenValidationError(false)}
          text={validationErrorText}
        />
      )}
    </>
  );
}

NewOrganisationDialog.propTypes = {
  industrySectors: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewOrganisationDialog);
