//@ts-nocheck
import React, { Component, FC, useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import DashboardModal from '../dashboard/DashboardModal';
import PdfCreator from '../PdfCreator';
import IDService from '../../services/IdService';
import { ReportHistoryChart } from '../charts/ReportHistoryChart';
import { getPdfFileName, getSettings } from '../../helpers/data-helper';
import WidgetTitle from '../help-button/widget-title';
import {
  ReportHistoryDefaultSettings,
  WidgetSettings,
} from './widgets-default-settings';
import TimeServiceProvider from '../../services/TimeService';
import { WidgetCode } from './widgetCodes';
import { getReportWidgetData } from '../../services/WidgetDataService';
import CircularProgressCentral from '../common/CircularProgressCentral';
import { widgetPropCodes } from '../widgets-manager-page/widgets-management-constants';
import { Filter, filters, GetActiveFilters } from '../../signals/filterService';
import { isServerError } from '../../services/utils';
import { WithStyles } from '@material-ui/styles';
import { ReportHistoryDto } from '../../api/originalMappings';
import { ReportHistoryDtoSchema } from '../../api/zodSchemas';
import { logDataFetchError, logResponseParseError } from '../../services/utils';

const styles = (_: any) =>
  createStyles({
    card: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    chartContent: {
      flexGrow: 1,
    },
    modalWrap: {
      height: 400,
    },
  });

interface Props extends WithStyles<typeof styles> {
  settings: WidgetSettings;
  rawSettings: WidgetSettings;
}

const ReportHistory: FC<Props> = ({ classes, settings, rawSettings }) => {
  const timeService = TimeServiceProvider();
  const [showModal, setShowModal] = useState(false);
  const [brandFilters, setBrandFilters] = useState<Array<Filter>>([]);
  const [data, setData] = useState<ReportHistoryDto | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const setModal = (showModal) => () => {
    const brandFilters = GetActiveFilters().filter(
      (f) => f.property === 'Organisation'
    );
    setShowModal(showModal);
    setBrandFilters(brandFilters);
  };

  const getDataFromAPI = () => {
    setIsLoading(true);
    getReportWidgetData(WidgetCode.reportHistory)
      .then((res) => {
        //here zod is used to do a runtime check whether the parsed res from api is the
        //correct format
        const schemaMatch = ReportHistoryDtoSchema.safeParse(res);
        if (schemaMatch.success) {
          setData(schemaMatch.data);
        } else {
          logResponseParseError(schemaMatch.error);
        }
      })
      .catch((err) => logDataFetchError(err, 'ReportHistory'))
      .finally(() => setIsLoading(false));
  };

  useEffect(function getDataOnLoad() {
    let isMounted = true;

    timeService.subscribe(getDataFromAPI);

    const fetchData = async () => {
      getDataFromAPI();
      if (!isMounted) {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      timeService.unsubscribe(getDataFromAPI);
    };
  }, []);

  const {
    title,
    info,
    axisY,
    totalItemsLabel,
    reportTypeAggregationThreshold,
  } = getSettings(settings, ReportHistoryDefaultSettings);
  const elementId = IDService.Generate();
  const fileName = getPdfFileName(title);

  const aggregationProp =
    rawSettings &&
    rawSettings.usedProps.find(
      (prop) => prop.code === widgetPropCodes.reportTypeAggregationThreshold
    );
  const aggregationEnabled = !!aggregationProp && aggregationProp.isEnabled;

  let aggregationThreshold = parseInt(reportTypeAggregationThreshold);
  if (!Number.isInteger(aggregationThreshold)) {
    aggregationThreshold = parseInt(
      ReportHistoryDefaultSettings.reportTypeAggregationThreshold
    );
  }

  return isLoading || !data ? (
    <CircularProgressCentral />
  ) : (
    <>
      <Card variant="outlined" className={classes.card}>
        <CardHeader
          title={<WidgetTitle title={title} info={info} />}
          titleTypographyProps={{ variant: 'h6' }}
          action={
            <div>
              <PdfCreator targetElementId={elementId} fileName={fileName} />
              <IconButton onClick={setModal(true)}>
                <ZoomOutMapIcon fontSize="small" />
              </IconButton>
            </div>
          }
        />
        <CardContent id={elementId} className={classes.chartContent}>
          <ReportHistoryChart
            data={data}
            tooltip={true}
            legend={true}
            axisY={axisY}
            aggregationEnabled={aggregationEnabled}
            aggregationThreshold={aggregationThreshold}
          />
        </CardContent>
      </Card>

      <DashboardModal
        handleClose={setModal(false)}
        title={title}
        open={showModal}
      >
        <div className={classes.modalWrap}>
          <ReportHistoryChart
            data={data}
            tooltip={true}
            legend={true}
            aggregationEnabled={aggregationEnabled}
            aggregationThreshold={aggregationThreshold}
          />
        </div>
        <PdfCreator fileName={fileName} />
      </DashboardModal>
    </>
  );
};

export default withStyles(styles)(ReportHistory);
