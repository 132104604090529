import React, { FC, useState } from 'react';
import API from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import { Tooltip, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../dialog/ConfirmDialog';
import { ActionDto } from '../../api/apiDtos';

interface Props {
  action: ActionDto;
  onDelete: () => void;
}

const DeleteActionDialog: FC<Props> = ({ action, onDelete }) => {
  const [showDialog, setShowDialog] = useState(false);

  const deleteAction = () => {
    API.del<ActionDto, any>(ApiRoutes.deleteAction, { id: action.id }).then(
      () => {
        onDelete();
        setShowDialog(false);
      }
    );
  };

  return (
    <>
      <Tooltip title="Delete action">
        <IconButton
          aria-label="DeleteAction"
          color="primary"
          onClick={() => setShowDialog(true)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog
        open={showDialog}
        onCancel={() => setShowDialog(false)}
        onConfirm={deleteAction}
        title="Delete action"
        confirm="Delete"
      >
        <Typography variant="body1">
          {`Action "${action.title}" will be deleted.`}
          <br />
          Do you wish to continue?
        </Typography>
      </ConfirmDialog>
    </>
  );
};

export default DeleteActionDialog;
