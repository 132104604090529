// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { getSettings } from '../../helpers/data-helper';
import { PriorityRootCauseClustersDefaultSettings } from './widgets-default-settings';
import ClassificationTrendsBase from './ClassificationTrendsBase';
import { WidgetCode } from './widgetCodes';
import { getReportWidgetData } from '../../services/WidgetDataService';

function PriorityRootCauseClusters({ limit, settings }) {
  const {
    title,
    info,
    rootCauseType,
    reportsPercent,
    previousPeriodReportsPercent,
    currentPeriodReportsPercent,
    noDataLabel,
  } = getSettings(settings, PriorityRootCauseClustersDefaultSettings);

  const fetchTrendsFunc = () =>
    getReportWidgetData(WidgetCode.priorityRootCauseClusters);

  return (
    <ClassificationTrendsBase
      trendsFetchFunc={fetchTrendsFunc}
      limit={limit}
      title={title}
      info={info}
      noDataLabel={noDataLabel}
      classificationType={rootCauseType}
      previousPeriod={previousPeriodReportsPercent}
      currentPeriod={currentPeriodReportsPercent}
      reportsPercent={reportsPercent}
      widgetName="PriorityRootCauseClusters"
    />
  );
}

PriorityRootCauseClusters.propTypes = {
  limit: PropTypes.number,
  settings: PropTypes.object,
};

export default PriorityRootCauseClusters;
