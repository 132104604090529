import { Button, Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { AlertNotificationDto } from '../../api/apiDtos';
import API from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import { AlertIcon } from './AlertIcon';
import { useHistory } from 'react-router-dom';

interface Props {
  notification: AlertNotificationDto;
  type: 'read' | 'unread';
  pushUpdatedNotification: (notification: AlertNotificationDto) => void;
  onClose: () => void;
}

export const NotificationItem: FC<Props> = ({
  notification,
  type,
  pushUpdatedNotification,
  onClose,
}) => {
  const handleMarkRead = () => {
    //make api call to mark read
    API.put<any, AlertNotificationDto>(
      `${ApiRoutes.markAlertNotificationRead}/${notification.id}`,
      notification.id
    )
      .then((res) => pushUpdatedNotification(res))
      .catch((err) => console.error(err));
  };

  const handleMarkUnread = (event: any) => {
    //make api call to mark unread
    event.stopPropagation();

    API.put<any, AlertNotificationDto>(
      `${ApiRoutes.markAlertNotificationUnread}/${notification.id}`,
      notification.id
    )
      .then((res) => pushUpdatedNotification(res))
      .catch((err) => console.error(err));
  };

  const history = useHistory();

  return (
    <div style={{ width: '100%' }} onClick={handleMarkRead}>
      <Grid
        onClick={() => {
          history.push(`/trackers/${notification.alertId}`);
          onClose();
        }}
        container
        spacing={2}
        style={{
          backgroundColor: !notification.isRead ? '#ffe3e3' : 'inherit',
        }}
      >
        <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
          <AlertIcon type={type} />
        </Grid>
        <Grid
          item
          xs={12}
          sm
          container
          style={{
            paddingTop: '1em',
            paddingLeft: '1em',
            paddingBottom: '1em',
          }}
        >
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1">
                {notification.alert.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Triggered at{' '}
                {new Date(notification.createdDate).toLocaleDateString()}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {!notification.isRead ? (
              <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
                <circle cx="25" cy="25" r="3" fill="red" />
              </svg>
            ) : (
              <Button
                size="small"
                style={{ fontSize: '0.6em' }}
                onClick={handleMarkUnread}
              >
                Mark unread
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
