/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useEffect, useState, useContext } from 'react';
import {
  CircularProgress,
  Dialog,
  ThemeOptions,
  ThemeProvider,
  createTheme,
} from '@material-ui/core';
import LoginPage from './components/login/LoginPage';
import { Route } from 'react-router';
import Header from './components/Header';
import Dashboard from './components/dashboard/Dashboard';
import UserPersonalDetails from './components/user-management/UserPersonalDetails';
import ReportUpload from './components/upload/ReportUpload';
import Licences from './components/licensing/Licences';
import ActionsManager from './components/actions/ActionsManager';
import DashboardLayoutConfigurator from './components/gridlayout/dashboard-layout-configurator';
import WidgetsManagement from './components/widgets-manager-page/widgets-management';
import TrackerPage from './components/tracker/Trackers';
import About from './components/About';
import ProtectedRoute from './components/ProtectedRoute';
import GeneralManagement from './components/GeneralManagement';
import FacilityManagement from './components/facilities/FacilityManagement';
import AliasManagement from './components/alias/AliasManagement';
import SuperUserPanel from './components/super-user/SuperUserPanel';
import ActivityLogs from './components/activity-logs/ActivityLogs';
import { ROUTES, LAYOUT, DEFAULT_THEME_CONFIG } from './constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeConfigDto } from './api/apiDtos';
import AlertsView from './components/Alerts/AlertsView';
import { AlertsConfigContext } from './context/AlertConfigContextProvider';
import DualActionChip from './components/dual-action-button/DualActionChip';
import useAuthorization from './hooks/useAuthorisationManager';
import { User, useAuth0 } from '@auth0/auth0-react';
import api from './services/ApiInstance';
import ApiRoutes from './services/ApiRoutes';
import useLoggedInUser from './useLoggedInUser';
import { useHistory } from 'react-router-dom';

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: '10.5px 9px',
        cursor: 'pointer',
        fontSize: 13,
      },
    },
    MUIDataTableHeadCell: {
      root: {
        whiteSpace: 'nowrap',
      },
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto',
    subtitle1: {
      fontSize: 16,
      letterSpacing: 0.15,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 14,
      letterSpacing: 0.1,
      fontWeight: 500,
      color: '#5E6366',
    },
    body1: {
      fontSize: 16,
      letterSpacing: 0.1,
      fontWeight: 'normal',
    },
    body2: {
      fontSize: 14,
      letterSpacing: 0.1,
      fontWeight: 'normal',
      lineHeight: '16px',
    },
    button: {
      fontSize: 14,
      letterSpacing: 0.75,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: 12,
      fontWeight: 'normal',
    },
    overline: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 1,
      lineHeight: 'unset',
    },
    h6: {
      fontSize: '1rem',
    },
  },
  palette: {
    primary: {
      main: '#D94936',
    },
    secondary: {
      main: '#D94936',
    },
  },
} as ThemeOptions & { overrides?: CustomOverrides });

interface CustomOverrides {
  MUIDataTableHeadCell?: {
    root?: {
      whiteSpace?: string;
    };
  };
}

const App: FC = () => {
  const { hasAccess } = useAuthorization();
  const history = useHistory();

  const { alertsConfig, setAlertsConfig } = useContext(AlertsConfigContext);
  const [loadApp, setLoadApp] = useState<boolean>(false);
  const [loadingLogout, setLoadingLogout] = useState(false);
  const { setCurrentUser } = useLoggedInUser();

  const [themeConfig, setThemeConfig] =
    useState<ThemeConfigDto>(DEFAULT_THEME_CONFIG);

  const { isAuthenticated, isLoading, logout, getAccessTokenSilently, user } =
    useAuth0();

  useEffect(() => {
    var error = localStorage.getItem('Error');
    if (error && !loadApp && !isLoading && !isAuthenticated) {
      if (!error.includes('TypeError')) {
        toast.error(error);
        console.log('Error:', error);
      }
    }
  }, [isLoading, isAuthenticated, loadApp]);

  useEffect(() => {
    //set refresh token from authzero and maybe check on isAuthenticated and update if token expires
    const initialise = async () => {
      if (user !== undefined) {
        const token = await getAccessTokenSilently();
        if (token) {
          localStorage.setItem('accessToken', token);
          localStorage.setItem('currentUserEmail', user.email);
          await api
            .get<string, User>(`${ApiRoutes.login}/${user.email}`)
            .then((res) => {
              if (res) {
                setCurrentUser(res);
                validateAppLoader(
                  localStorage.getItem('accessToken'),
                  localStorage.getItem('currentUserEmail'),
                  localStorage.getItem('redirectAfterLogin')
                );
              }
            })
            .catch((e) => {
              setLoadApp(false);
              localStorage.setItem('Error', e);
              logout({ logoutParams: { returnTo: window.location.origin } });
              setCurrentUser(null);
              localStorage.removeItem('accessToken');
              localStorage.removeItem('currentUserEmail');
            });
        }
      }
    };
    initialise();
  }, [user]);

  const handleLogout = (e) => {
    e.preventDefault();
    setLoadingLogout(true);
    logout({ logoutParams: { returnTo: window.location.origin } });
    setCurrentUser(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('currentUserEmail');
  };

  function validateAppLoader(accessToken, currentEmail, redirectUrl) {
    try {
      if (accessToken && currentEmail) {
        if (redirectUrl) {
          history.push(redirectUrl);
          localStorage.removeItem('redirectAfterLogin');
        }
        setLoadApp(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    async function checkToken() {
      const token = await getAccessTokenSilently();
      if (!token || !isAuthenticated) {
        setLoadApp(false);
        setCurrentUser(null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('currentUserEmail');
      }
    }
    checkToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  return (
    <>
      {loadingLogout ? (
        <CircularProgress
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            color: '#d94936',
          }}
        />
      ) : (
        <>
          {loadApp && isAuthenticated ? (
            <ThemeProvider theme={theme}>
              <div style={{ height: '100%', background: '#E2E2E2' }}>
                <Header
                  loggedIn={loadApp && isAuthenticated}
                  logoutCallback={handleLogout}
                  themeConfig={themeConfig}
                />
                <div style={{ paddingTop: LAYOUT.HeaderHeight }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'right',
                      maxWidth: '100%',
                    }}
                  >
                    <DualActionChip />
                  </div>
                  <Route exact path="/" component={Dashboard} />
                  <ProtectedRoute
                    exact
                    path={ROUTES.UsersAndOrganisations}
                    component={GeneralManagement}
                    allowed={hasAccess(ROUTES.UsersAndOrganisations)}
                    redirectTo="/"
                  />
                  <Route
                    exact
                    path={ROUTES.UserProfile}
                    component={UserPersonalDetails}
                  />
                  <ProtectedRoute
                    exact
                    path={ROUTES.Upload}
                    component={ReportUpload}
                    allowed={hasAccess(ROUTES.Upload)}
                    redirectTo="/"
                  />
                  <Route path={ROUTES.Licences} component={Licences} />
                  <Route
                    exact
                    path={ROUTES.Actions}
                    component={ActionsManager}
                  />
                  <ProtectedRoute
                    exact
                    path={ROUTES.DashboardLayout}
                    component={DashboardLayoutConfigurator}
                    allowed={hasAccess(ROUTES.DashboardLayout)}
                    redirectTo="/"
                  />
                  <ProtectedRoute
                    exact
                    path={ROUTES.WidgetsAndFilters}
                    component={WidgetsManagement}
                    allowed={hasAccess(ROUTES.WidgetsAndFilters)}
                    redirectTo="/"
                  />
                  <Route exact path={ROUTES.Trackers} component={TrackerPage} />
                  <Route
                    exact
                    path={ROUTES.SpecificAlert}
                    component={TrackerPage}
                  />
                  <Route exact path={ROUTES.About} component={About} />
                  <ProtectedRoute
                    exact
                    path={ROUTES.Facilities}
                    component={FacilityManagement}
                    allowed={hasAccess(ROUTES.Facilities)}
                    redirectTo="/"
                  />
                  <ProtectedRoute
                    exact
                    path={ROUTES.Aliases}
                    component={AliasManagement}
                    allowed={hasAccess(ROUTES.Aliases)}
                    redirectTo="/"
                  />
                  <ProtectedRoute
                    exact
                    path={ROUTES.SuperUserPanel}
                    component={SuperUserPanel}
                    allowed={hasAccess(ROUTES.SuperUserPanel)}
                    redirectTo="/"
                  />
                  <ProtectedRoute
                    exact
                    path={ROUTES.ActivityLogs}
                    component={ActivityLogs}
                    allowed={hasAccess(ROUTES.ActivityLogs)}
                    redirectTo="/"
                  />
                </div>
                {alertsConfig !== '' ? (
                  <Dialog
                    onClose={() => setAlertsConfig('')}
                    open={alertsConfig !== ''}
                    className="alertsConfig"
                  >
                    {alertsConfig !== '' && <AlertsView Type={alertsConfig} />}
                  </Dialog>
                ) : (
                  setAlertsConfig('')
                )}
              </div>
            </ThemeProvider>
          ) : !loadApp && !isLoading && !isAuthenticated ? (
            <LoginPage />
          ) : (
            <CircularProgress
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                color: '#d94936',
              }}
            />
          )}
          <ToastContainer position="bottom-right" theme="colored" />
        </>
      )}
    </>
  );
};

export default App;
