// @ts-nocheck
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { withStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = {
  root: {
    borderRadius: 10,
    border: '1px dashed grey',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    textAlign: 'center',
    lineHeight: 1.5,
  },
};

const FileDropzone = (props) => {
  const {
    classes,
    accept,
    onDrop,
    noClick,
    multiple,
    maxFiles,
    width,
    height,
    className,
    text,
  } = props;
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
    multiple,
    maxFiles,
    noClick,
  });

  const combinedClass = className
    ? `${classes.root} ${className}`
    : classes.root;

  return (
    <div
      className={combinedClass}
      style={{ width, height, cursor: noClick ? 'default' : 'pointer' }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className={classes.content}>
        <p>{text}</p>
        {!noClick && (
          <>
            <p>or</p>
            <Button variant="contained" color="primary" size="small">
              Browse
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

FileDropzone.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrop: PropTypes.func.isRequired,
  accept: PropTypes.string,
  noClick: PropTypes.bool,
  multiple: PropTypes.bool,
  maxFiles: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  text: PropTypes.string,
};

FileDropzone.defaultProps = {
  noClick: true,
  multiple: false,
  maxFiles: 1,
  width: 400,
  height: 150,
  text: 'Drag and drop a file',
};

export default withStyles(styles)(FileDropzone);
