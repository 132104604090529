import React, { useState, useContext, useEffect, FC } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  IsFilterActive,
  UpdateFilter,
  AddFilter,
  FilterTags,
} from '../../signals/filterService';
import AdvancedSearchHelp from './AdvancedSearchHelp';
import { ADVANCED_SEARCH_PREFIX } from '../../constants';
import { createStyles, WithStyles } from '@material-ui/styles';

const styles = createStyles({
  root: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    width: 500,
    transition: 'all 0.25s',
    height: 40,
    background: 'rgba(250,250,250,0.2)',
    boxShadow: 'unset',
  },
  input: {
    paddingLeft: 44,
    flex: 1,
    transition: 'all 0.25s',
    color: '#7B7B7B',
    height: '100%',
    '& input': {
      padding: 0,
    },
    border: '1px solid #7B7B7B',
    borderRadius: '4px',
  },
  focused: {
    background: '#FFF',
    opacity: 1,
    color: 'black !important',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  iconButton: {
    padding: 10,
    margin: 2,
    color: '#7B7B7B !important',
  },
  progress: {
    padding: 10,
    margin: 2,
  },
  clearButton: {
    position: 'absolute',
    zIndex: 999,
  },
});

interface Props extends WithStyles<typeof styles> {}

const SearchBox: FC<Props> = ({ classes }) => {
  const [searchText, setSearchText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [focusBar, setFocusBar] = useState(false);

  const textChangeHandler = (event) => {
    setSearchText(event.target.value);
  };

  const isAdvancedSearch = (searchText) => {
    const words = searchText ? searchText.split(' ') : [];
    return words.length > 1;
  };

  const submitHandler = () => {
    let currentSearchText = searchText && searchText.trim();

    if (
      isAdvancedSearch(currentSearchText) &&
      !currentSearchText.startsWith('Id:')
    ) {
      currentSearchText = `${ADVANCED_SEARCH_PREFIX}${currentSearchText}`;
    }

    // Filter object
    const filter = {
      name: currentSearchText,
      property: FilterTags.report.search,
      value: currentSearchText,
      count: 0,
    };

    if (IsFilterActive(filter)) {
      UpdateFilter(filter);
    } else {
      AddFilter(filter);
    }

    setSearchText('');
  };

  const keySubmitHandler = (e) => {
    if (e.keyCode === 13) {
      submitHandler();
    }
  };

  return (
    <Paper className={classes.root}>
      {searchText !== '' && (
        <IconButton
          onClick={() => setSearchText('')}
          color="default"
          className={classes.clearButton}
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      )}
      <InputBase
        className={`${classes.input} ${focusBar ? classes.focused : ''}`}
        placeholder="Search"
        value={searchText}
        onChange={textChangeHandler}
        onKeyDown={keySubmitHandler}
        onMouseEnter={() => setFocusBar(true)}
        onMouseLeave={() => setFocusBar(false)}
      />
      {isSubmitting ? (
        <CircularProgress size={30} className={classes.progress} />
      ) : (
        <>
          <IconButton onClick={submitHandler} className={classes.iconButton}>
            <SearchIcon />
          </IconButton>
          <AdvancedSearchHelp />
        </>
      )}
    </Paper>
  );
};

export default withStyles(styles)(SearchBox);
