// @ts-nocheck
import React, { useState, useEffect } from 'react';
import DashboardModal from '../dashboard/DashboardModal';
import { getReportsWithPagination } from '../../services/WidgetDataService';
import { useTableColumns } from '../../context/TableColumnsContext';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core';
import DataTableDetails from './DataTableDetails';
import ApiRoutes from '../../services/ApiRoutes';

const styles = {
  table: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    height: '100%',
  },
};

const FilteredReportsViewer = ({
  open,
  handleClose,
  filters,
  title,
  classes,
}) => {
  const [reports, setReports] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedReport, setSelectedReport] = useState(null);
  const { reportTableColumnsExpanded } = useTableColumns();

  const getReports = async (page, rowsPerPage, sortBy, sortDesc) => {
    const { data, total } = await getReportsWithPagination(
      page,
      rowsPerPage,
      sortBy,
      sortDesc,
      filters
    );
    setReports(data);
    setCount(total);
  };

  useEffect(() => {
    if (open) {
      getReports(0, 10, null, false);
    }
  }, [open]);

  const onRowClick = (_, index) => {
    const report = reports[index.dataIndex];
    setSelectedReport(report);
  };

  const onTableChange = (action, tableState) => {
    if (['changePage', 'changeRowsPerPage', 'sort'].includes(action)) {
      const { page, rowsPerPage, sortOrder } = tableState;
      getReports(
        page,
        rowsPerPage,
        sortOrder.name,
        sortOrder.direction === 'desc'
      );
    }
  };

  const options = {
    selectableRows: 'none',
    print: false,
    search: false,
    filter: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    onRowClick,
    onTableChange,
    count,
  };

  return (
    <DashboardModal title={title} open={open} handleClose={handleClose}>
      <MUIDataTable
        data={reports}
        options={options}
        columns={reportTableColumnsExpanded}
        className={classes.table}
      />
      {selectedReport && (
        <DashboardModal
          open={true}
          handleClose={() => setSelectedReport(null)}
          title="Report details"
        >
          <DataTableDetails
            record={selectedReport}
            columns={reportTableColumnsExpanded}
            url={ApiRoutes.getReportExtras}
          />
        </DashboardModal>
      )}
    </DashboardModal>
  );
};

FilteredReportsViewer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  title: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FilteredReportsViewer);
