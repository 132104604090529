// @ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton, Typography, withStyles } from '@material-ui/core';
import ConfirmDialog from '../dialog/ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import { toast } from 'react-toastify';

const styles = {
  disabledIcon: {
    padding: 12,
    margin: '0 5px',
  },
  icon: {
    margin: '0 5px',
  },
};

const DELETION_ALLOWED_TOOLTIP = 'Delete facility';
const DELETION_NOT_ALLOWED_TOOLTIP =
  'Facility with dependent reports cannot be deleted';

const DeleteFacilityDialog = ({ facility, onDelete, classes }) => {
  const [open, setOpen] = useState(false);

  const deleteFacility = () => {
    const url = `${ApiRoutes.deleteFacility}?id=${facility.id}`;
    api.del(url).then(() => {
      toast.success('Facility deleted');
      onDelete();
      setOpen(false);
    });
  };

  return (
    <>
      <Tooltip
        title={
          facility.hasDependentReports
            ? DELETION_NOT_ALLOWED_TOOLTIP
            : DELETION_ALLOWED_TOOLTIP
        }
      >
        {facility.hasDependentReports ? (
          <DeleteIcon color="disabled" className={classes.disabledIcon} />
        ) : (
          <IconButton
            color="primary"
            className={classes.icon}
            onClick={() => setOpen(true)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Tooltip>
      <ConfirmDialog
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={deleteFacility}
        title="Delete facility"
        confirm="Delete"
      >
        <Typography variant="body1">
          {`Facility '${facility.name}' will be deleted.`}
          <br />
          Do you wish to continue?
        </Typography>
      </ConfirmDialog>
    </>
  );
};

DeleteFacilityDialog.propTypes = {
  facility: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeleteFacilityDialog);
