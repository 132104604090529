import React, { FC, useRef } from 'react';
import Colours from '../../services/ColourService';
import { ChartDataDto } from '../../api/originalMappings';
import HighchartsReact, {
  HighchartsReactProps,
} from 'highcharts-react-official';
import Highcharts from 'highcharts';

type BasicInfo = {
  name: string;
  id: string;
};

type CountInfo = {
  count: number;
};

// This represents the other properties excluding 'name' and 'id'
type AdditionalProperties = {
  [key: string]: number;
};

type ColumnChartData = BasicInfo &
  CountInfo &
  Omit<AdditionalProperties, 'name' | 'id'>;

interface Props extends HighchartsReactProps {
  chartData: ColumnChartData[];
  chartShape: any[];
  header: string;
  fillOpacity: number;
  hideToolTip: boolean;
  label: string;
  onSelectItem: (item: ChartDataDto) => void;
}

const ColumnChart: FC<Props> = ({
  chartData,
  chartShape,
  header,
  fillOpacity,
  hideToolTip,
  label,
  onSelectItem,
  ...highChartProps
}) => {
  const colours = Colours.materialColors();
  const palette = chartShape.reduce((palette, c) => {
    palette[c] = colours.next();
    return palette;
  }, {});

  if (chartShape.length === 1) palette[chartShape[0]] = '#FF5722';

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const structureData = (
    chartData: ColumnChartData[]
  ): Highcharts.SeriesBarOptions[] => {
    const keys = chartData.map((x) => x.name);

    // Map over the keys array to create a new array of objects.
    const seriesDataKeys = keys.map((name) => ({
      name,
    }));

    const formatReportCounts = (
      chartData: ColumnChartData[],
      key: string
    ): any[] => {
      return chartData.map((obj) => {
        // Destructure to separate out id, count, name, and collect the rest into 'remaining'
        const { id, count, name, ...remaining } = obj;

        // Map over the remaining keys and create new objects with the desired structure
        return Object.keys(remaining).map((key) => ({
          name: key,
          y: remaining[key],
        }));
      });
    };

    return seriesDataKeys.map((x, i) => ({
      ...x,
      type: 'bar',
      data: formatReportCounts(chartData, x.name)[i],
    }));
  };

  //honestly man idek... it works
  const createHighchartData = (structuredData: Highcharts.SeriesBarOptions[]) => {
    let reportTypes: string[] = []

    for (let org of structuredData) {
      reportTypes.push(...org.data.map(x => x['name']))
    }

    //remove duplicates
    const reportTypesSet = [...new Set(reportTypes)]

    let actualData: any[] = []

    reportTypesSet.forEach(type => {
      const thisTypeDataArray: number[] = []

      structuredData.forEach((org, i) => {
        if (org.data.find(x => x['name'] === type)) {
          //@ts-ignore
          thisTypeDataArray[i] = org.data.find(x => x['name'] === type).y
        } else {
          thisTypeDataArray[i] = 0
        }
      })

      actualData.push({
        name: type,
        data: thisTypeDataArray
      })
    })

    return actualData
  }

  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'bar',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      visible: true,
      categories: chartData.map((x) => x.name), // List of names for x-axis
    },
    yAxis: {
      title: {
        text: 'Number of Reports',
      },
      type: 'linear',
    },
    legend: {
      enabled: false, // Disable the legend
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: function() {
            if (this.y === 0) {
              return null;
            } else {
              return this.y;
            }
          }
        }
      }
    },
    series: createHighchartData(structureData(chartData))
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      ref={chartComponentRef}
      {...highChartProps}
    />
  );
};

export default ColumnChart;
