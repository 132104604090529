// @ts-nocheck
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { UNMAPPED_ITEMS_POOL, CARD_TYPE, EXTRA_ITEMS_POOL } from './constants';
import { IsCardNameValid } from './mapper-data';

export const MovableItem = ({
  name,
  title,
  index,
  currentCardName,
  moveCardHandler,
  setItems,
}) => {
  const getCardName = (item, currentItem, cardName) => {
    if (item.name === currentItem.name) {
      return cardName;
    }

    return item.card === cardName && cardName !== EXTRA_ITEMS_POOL
      ? UNMAPPED_ITEMS_POOL
      : item.card;
  };

  const changeItemCard = (currentItem, cardName) => {
    setItems((prevState) => {
      return prevState.map((e) => {
        return {
          ...e,
          card: getCardName(e, currentItem, cardName),
        };
      });
    });
  };

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: CARD_TYPE,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCardHandler(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    // type: CARD_TYPE,
    item: { index, name, currentCardName, type: CARD_TYPE },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        const { name } = dropResult;
        if (IsCardNameValid(name)) {
          changeItemCard(item, name);
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  drag(drop(ref));

  return (
    <div ref={ref} className="movable-item" style={{ opacity }}>
      {name}
      {title}
    </div>
  );
};
