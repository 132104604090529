import React, { FC, useRef } from 'react';
import './style.css';
import HighchartsReact, { HighchartsReactProps } from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';

const DATA_KEY = 'date';

interface Props extends HighchartsReactProps {
  chartData: any[]
  showLegend: boolean,
  showTooltip: boolean,
  axisY?: string,
  isPercentages: boolean
}

export const TrackerChart: FC<Props> = (props) => {
  const {
    chartShape,
    chartData,
    showTooltip,
    showLegend,
    actions,
    axisY = 'Report Trends',
    isPercentages,
    ...highChartProps
  } = props;

  const axisYunit = isPercentages ? '%' : 'numbers';
  const minDate = chartData.length !== 0 ? new Date(chartData.at(0).date) : null
  const maxDate = chartData.length !== 0 ? new Date(chartData.at(-1).date) : null
  
  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'spline'
    },
    title: {
      text: '',
    },
    yAxis: {
      title: {
        text: `${axisY}, ${axisYunit}`,
      }
    },
    xAxis: {
      type: 'datetime',
      // tickInterval: 1,
      // min: minDate,
      // max: maxDate
    },
    legend: {
      enabled: showLegend,
      // layout: 'vertical',
      // align: 'right',
      // verticalAlign: 'middle'
    },
    tooltip: {
      enabled: showTooltip,
      formatter: function () {
        return `<b>${moment(this.x).format('dddd, D MMM')}</b><br>${this.series.name}: ${this.y}`;
      }
    },
    plotOptions: {
      spline: {
        connectEnds: true,
        connectNulls: true,
        marker: {
          enabled: true        
        }
      }
    },
    series: [
      {
        type: 'spline',
        name: 'Incidents',
        data: chartData.length !== 0 ? chartData.map(x => [moment(x.date, "DD/MM/YY").toDate().getTime(), x['Incidents']] ?? [moment(x.date, "DD/MM/YY").toDate().getTime(), 0]) : [],
      },
      {
        type: 'spline',
        name: 'Near Misses',
        data: chartData.length !== 0 ? chartData.map(x => [moment(x.date, "DD/MM/YY").toDate().getTime(), x['Near Misses']] ?? [moment(x.date, "DD/MM/YY").toDate().getTime(), 0]) : [],
      },
      {
        type: 'spline',
        name: 'Observations',
        data: chartData.length !== 0 ? chartData.map(x => [moment(x.date, "DD/MM/YY").toDate().getTime(), x['Observations']] ?? [moment(x.date, "DD/MM/YY").toDate().getTime(), 0]) : [],
      }
    ]
  }

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      ref={chartComponentRef}
      {...highChartProps}
    />
  );
};


export default TrackerChart;
